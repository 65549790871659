//#region Imports
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Heart, Home,HelpCircle,Smile, Coffee,  Zap, Cookie,CreditCard ,ArrowLeft, Clipboard,Share2, Search, TrendingUp, DollarSign, Activity, BarChart, X,Users, Trophy, Lock, Star, Battery, Parachute, Gift, Settings, ChefHat, Briefcase, Users as UsersIcon, Share, CreditCard as CreditCardIcon, Briefcase as BriefcaseIcon, Cake as CakeIcon, Cookie as CookieIcon, BarChart as BarChartIcon, TrendingUp as TrendingUpIcon, RefreshCw, User, BarChart2 } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis,PieChart, Pie, Cell, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { initUtils } from '@tma.js/sdk';
import { 
  FaBriefcase, 
  FaChartPie, 
  FaCoins, 
  FaCookieBite, 
  FaChartLine, 
  FaUsers, 
  FaShareAlt, 
  FaSyncAlt,
  FaDollarSign, FaSpinner } from 'react-icons/fa';
  import { GiCookingPot } from 'react-icons/gi'; // Example of a better icon



import { useSwipeable } from 'react-swipeable';
import { debounce } from 'lodash';
import { motion, AnimatePresence } from 'framer-motion';
import placeholderImage from './egg.png'; // Make sure to add this image to your project
import kitchenBackground from './kitchen.jpg'; // Make sure to add this image to your project


import './index.css';
import cutebase from './cutebase.png';
import happypat from './happypat.png';
import happyfeed from './happyfeed.png';
import loungeBackground from './lounge.jpg';  // Adjust the path as necessary


import standMixerImage from './stand-mixer.png';
import apronImage from './apron.png';
import cookieCarImage from './cookie-car.png';
import keyringImage from './keyring.png';
import flowersImage from './flowers.png';

import WebApp from '@twa-dev/sdk';
import happystate from './happystate.png';
import eggImage from './egg.png';
import slightlyCrackedEgg from './slightly-cracked-egg.png';
import veryCrackedEgg from './very-cracked-egg.png';
import eggHatchingVideo from './egg-hatching.mp4';
import kawaiistatus from './kawaii-status.jpg';
import peachangry from './peach-angry.png';
import peachhappy from './peach-happy.png';
import peachnom1 from './nom1.mp4';
import peachnom2 from './peach-nom2.png';
import peachcontent from './peach-content.png';
import peachsad from './peach-sad.png';
import peachdefault from './peach-default.png';
import peachstatus from './peach-status.png';
import appleImage from './apples3.png';
import eggHatchingSound from './egg-hatching-sound.mp3'; // Make sure to add this MP3 file to your project
import peachidle from './peach-idle-animation.mp4'; // Make sure to add this MP3 file to your project
import peachPattingAnimation from './peach-patting-animation.mp4';
const tg = window.Telegram?.WebApp;

//#region Saves

async function saveUserData(username, gameState) {
  try {
    const collection = db.collection('users');
    await collection.updateOne(
      { username: username },
      {
        $set: {
          gameState: {
            ...gameState,
            tutorialShown: gameState.tutorialShown, // Ensure this is correctly set
            bakery: {
              ...gameState.bakery,
              created: gameState.bakery?.created ?? false,
              name: gameState.bakery?.name || null,
              dailyProduction: gameState.bakery?.dailyProduction || 0,
              totalProduced: gameState.bakery?.totalProduced || 0,
              efficiency: gameState.bakery?.efficiency || 0,
              userEfficiency: gameState.bakery?.userEfficiency || 0,
              employees: gameState.bakery?.employees || []
            }
          }
        }
      },
      { upsert: true }
    );
    console.log(`User data saved for ${username}:`, gameState);
    return { success: true };
  } catch (error) {
    console.error('Error saving user data:', error);
    throw error;
  }
}
//#region Loads
async function loadUserData(username) {
  if (!username) return null;
  try {
    console.log('Fetching user data for:', username);
    const response = await fetch(`/api/getUserData?username=${username}`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Received data from server:', data);
    if (data.gameState) {
      return {
        ...data.gameState,
        bakery: data.bakery || { created: false, name: null, dailyProduction: 0, totalProduced: 0 },
        lastUpdateTime: data.gameState.lastUpdateTime || Date.now(),
        tutorialShown: data.gameState.tutorialShown ?? false, // Use nullish coalescing
      };
    }
  } catch (error) {
    console.error('Error loading user data:', error);
  }
  return null;
}
window.onerror = function (message, source, lineno, colno, error) {
  console.log('Global error caught:', { message, source, lineno, colno, error });
  alert(`An error occurred: ${message}`);
  return true;
};

// Constants and Evolution Stages

const HEART_DECREASE_INTERVAL = 5000;
const APPLE_SPAWN_INTERVAL = 500;
const ANIMATION_DURATION = 1000;
const HATCHING_DURATION = 8000;
const MOOD_CHANGE_INTERVAL = 30000;
const WELL_FED_DURATION = 300000; // 5 minutes

const MAX_ENERGY = 1000;
const ENERGY_REGENERATION_RATE = 1; // energy per second
const ENERGY_REGENERATION_INTERVAL = 1000; // 1 second

const ENERGY_COSTS = {
  TAP: 1,
  PAT: 50,
  FEED: 100
};

const moodStates = {
  ECSTATIC: 'Ecstatic',
  HAPPY: 'Happy',
  CONTENT: 'Content',
  NEUTRAL: 'Neutral',
  BORED: 'Bored',
  HUNGRY: 'Hungry',
  SAD: 'Sad',
  UPSET: 'Upset',
  ANGRY: 'Angry'
};
//#region Stages

const evolutionStages = {
  Stranger: { image: cutebase, expNeeded: 0, level: 1, maxHearts: 100,cookiesPerHour: 1 },
  Acquaintance: { image: cutebase, expNeeded: 2000, level: 2, maxHearts: 200,cookiesPerHour: 3 },
  Friend: { image: cutebase,  expNeeded: 5000, level: 5, maxHearts: 300,cookiesPerHour: 5 },
  CloseFriend: { image: cutebase, expNeeded: 7000, level: 7, maxHearts: 400,cookiesPerHour: 10 },
  BestFriend: { image: cutebase,  expNeeded: 10000, level: 10, maxHearts: 500,cookiesPerHour: 20 },
  Crush: { image: cutebase,  expNeeded: 20000, level: 20, maxHearts: 600,cookiesPerHour: 35 },
  Girlfriend: { image: cutebase,  expNeeded: 60000, level: 60, maxHearts: 700,cookiesPerHour: 50 },
  Fiancee: { image: cutebase,  expNeeded: 80000, level: 80, maxHearts: 800,cookiesPerHour: 75 },
  Waifu: { image: cutebase, expNeeded: 100000, level: 100, maxHearts: 1000,cookiesPerHour: 100 },
  egg: { image: eggImage },
  slightlyCracked: { image: slightlyCrackedEgg },
  veryCracked: { image: veryCrackedEgg },
};

const moodThresholds = {
  ECSTATIC: { comfort: 90, fullness: 80 },
  HAPPY: { comfort: 75, fullness: 70 },
  CONTENT: { comfort: 60, fullness: 60 },
  NEUTRAL: { comfort: 40, fullness: 40 },
  BORED: { comfort: 30, fullness: 50 },
  HUNGRY: { comfort: 50, fullness: 20 },
  SAD: { comfort: 20, fullness: 30 },
  UPSET: { comfort: 10, fullness: 10 },
  ANGRY: { comfort: 0, fullness: 0 }
};

const moodInteractions = {
  ECSTATIC: {
    pat: { heartGain: 5, comfortGain: 10, expGain: 3 },
    feed: { heartGain: 5, fullnessGain: 10, expGain: 2 },
    tap: { heartGain: 5, expGain: 2 }
  },
  HAPPY: {
    pat: { heartGain: 4, comfortGain: 8, expGain: 2 },
    feed: { heartGain: 4, fullnessGain: 8, expGain: 1 },
    tap: { heartGain: 4, expGain: 2 }
  },
  CONTENT: {
    pat: { heartGain: 3, comfortGain: 6, expGain: 2 },
    feed: { heartGain: 2, fullnessGain: 6, expGain: 1 },
    tap: { heartGain: 4, expGain: 2 }
  },
  NEUTRAL: {
    pat: { heartGain: 2, comfortGain: 4, expGain: 1 },
    feed: { heartGain: 2, fullnessGain: 4, expGain: 1 },
    tap: { heartGain: 2, expGain: 1 }
  },
  BORED: {
    pat: { heartGain: 4, comfortGain: 8, expGain: 2 },
    feed: { heartGain: -2, fullnessGain: 2, expGain: 0 },
    tap: { heartGain: 3, expGain: 2 }
  },
  HUNGRY: {
    pat: { heartGain: -2, comfortGain: -4, expGain: 0 },
    feed: { heartGain: 5, fullnessGain: 10, expGain: 2 },
    tap: { heartGain: 2, expGain: 1 }
  },
  SAD: {
    pat: { heartGain: 4, comfortGain: 8, expGain: 2 },
    feed: { heartGain: 1, fullnessGain: 4, expGain: 1 },
    tap: { heartGain: -2, expGain: 0 }
  },
  UPSET: {
    pat: { heartGain: 3, comfortGain: 6, expGain: 1 },
    feed: { heartGain: 2, fullnessGain: 4, expGain: 1 },
    tap: { heartGain: 1, expGain: 1 }
  },
  ANGRY: {
    pat: { heartGain: -3, comfortGain: -6, expGain: 0 },
    feed: { heartGain: 4, fullnessGain: 8, expGain: 1 },
    tap: { heartGain: 2, expGain: 1 }
  }
};
// #region LoadingScreen
function LoadingScreen({ progress }) {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center z-50">
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${kitchenBackground})`,
          transform: 'scale(1)',
          transition: 'transform 0.5s ease-out',
        }}
      />
      <div className="relative z-10 bg-black bg-opacity-50 p-8 rounded-lg">
        <div className="text-3xl font-bold text-white mb-4">Loading...</div>
        <div className="w-64 h-4 bg-gray-700 rounded-full overflow-hidden">
          <div 
            className="h-full bg-indigo-600 transition-all duration-300 ease-out"
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="mt-2 text-white text-center">{Math.round(progress)}%</div>
      </div>
    </div>
  );
}
//#region Selection

// Character Selection Screen
function CharacterSelectionScreen({ onSelectCharacter, isDarkMode }) {
  const characters = [
    {
      id: 'peach',
      name: 'Momo-chan',
      image: peachdefault,
      description: 'Sweet and peachy!',
      available: true,
    },
    // Kawaii character is removed from the list
  ];

  return (
    <div className={`fixed inset-0 flex flex-col items-center justify-center p-4 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gradient-to-b from-blue-100 to-purple-100 text-black'}`}>
      <h2 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-white' : 'text-indigo-700'}`}>Choose your Character!</h2>
      <div className="flex flex-wrap justify-center gap-4">
        {characters.map((character) => (
          <div
            key={character.id}
            className={`w-64 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex flex-col items-center ${!character.available && 'opacity-50'} cursor-pointer transition-transform hover:scale-105`}
            onClick={() => character.available && onSelectCharacter(character.id)}
          >
            <div className="w-48 h-48 rounded-full bg-indigo-100 flex items-center justify-center overflow-visible mb-4">
              <img
                src={character.image}
                alt={character.name}
                className="w-full h-full object-cover transform scale-125"
                style={{ marginTop: '-10%' }}
              />
            </div>
            <h3 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-black'}`}>{character.name}</h3>
            <p className={`text-sm text-center mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{character.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
//#region Tap Limiter
// Tap Limiter
function TapLimiter({ currentTaps, maxTaps }) {
  const percentage = (currentTaps / maxTaps) * 100;

  return (
    <div className="fixed bottom-16 left-0 right-0 flex items-center justify-center">
      <div className="bg-white rounded-full shadow-md px-4 py-2 flex items-center">
        <Battery size={24} className="text-indigo-600 mr-2" />
        <span className="text-indigo-600 font-semibold">
          {currentTaps} / {maxTaps}
        </span>
      </div>
    </div>
  );
}

// Define the ExpBar component
const ExpBar = ({ exp, maxExp }) => {
  const progress = maxExp > 0 ? (exp / maxExp) * 100 : 0;
  return (
    <div className="w-full bg-gray-200 rounded-full h-2">
      <div
        className="bg-indigo-600 h-2 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
//#region Header

const CustomHeader = ({ 
  exp, 
  maxExp, 
  isDarkMode, 
  username, 
  onOpenStatus, 
  onOpenInvestment, 
  onOpenHireFriends 
}) => {
  const level = Math.floor(exp / 1000) + 1;
  const expPercentage = ((exp % 1000) / 1000) * 100;

  return (
    <div className="bg-black text-white p-2 w-full z-20">
      <div className="container mx-auto flex items-center">
        <div className="flex items-center mr-2">
          <div className="w-10 h-10 rounded-full bg-gray-700 mr-2 overflow-hidden">
          <img src={eggImage} alt="Avatar" className="w-full h-full object-cover" />
          </div>
          <span className="font-semibold">{username || 'User'}</span>
        </div>
        
        <div className="flex-grow flex items-center relative">
  <div className="w-full bg-gray-800 rounded-full h-4 flex-grow relative">
    <div 
      className="bg-blue-600 h-full rounded-full" 
      style={{width: `${expPercentage}%`}}
    ></div>
    <span className="absolute inset-0 flex items-center justify-center text-xs text-white">
      {exp % 1000}/{1000}
    </span>
  </div>
  <div className="absolute -bottom-4 left-0 right-0 flex justify-center">
    <span className="text-xs">Lvl. {level}</span>
  </div>
</div>

        <div className="flex space-x-2 ml-2">
          <button onClick={onOpenHireFriends} className="text-gray-300 hover:text-white transition-colors">
            <Users size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

//#region EggStage
function EggStage({ onHatch, onTap, characterType, isDarkMode }) {
  const [eggState, setEggState] = useState('egg');
  const [taps, setTaps] = useState(0);
  const [isHatching, setIsHatching] = useState(false);
  const [floatingHearts, setFloatingHearts] = useState([]);
  const eggRef = useRef(null);
  const audioRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    // Create audio element
    audioRef.current = new Audio(eggHatchingSound);
    audioRef.current.loop = true;

    // Attempt to play audio
    const playAudio = () => {
      audioRef.current.play().catch(error => {
        console.error('Error playing audio:', error);
        // Retry after a short delay
        setTimeout(playAudio, 1000);
      });
    };

    playAudio();

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
    };
  }, []);

  const handleTap = useCallback((e) => {
    if (isHatching) return;

    if (typeof WebApp !== 'undefined' && WebApp.HapticFeedback) {
      WebApp.HapticFeedback.impactOccurred('medium');
    }

    setTaps(prevTaps => {
      const newTaps = prevTaps + 1;
      onTap();

      if (eggRef.current) {
        eggRef.current.classList.add('wiggle');
        setTimeout(() => {
          if (eggRef.current) {
            eggRef.current.classList.remove('wiggle');
          }
        }, 500);
      }

      const rect = eggRef.current.getBoundingClientRect();
      const heartX = (e.touches ? e.touches[0].clientX : e.clientX) - rect.left;
      const heartY = (e.touches ? e.touches[0].clientY : e.clientY) - rect.top;
      const newHeart = {
        id: Date.now(),
        x: heartX,
        y: heartY,
        offsetX: Math.random() * 40 - 20,
      };
      setFloatingHearts(prev => [...prev, newHeart]);
      setTimeout(() => setFloatingHearts(prev => prev.filter(heart => heart.id !== newHeart.id)), 1000);

      if (newTaps >= 10 && !isHatching) {
        setIsHatching(true);
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
        setTimeout(() => {
          setIsHatching(false);
          onHatch(characterType);
        }, HATCHING_DURATION);
      } else if (newTaps >= 80) {
        setEggState('veryCracked');
      } else if (newTaps >= 40) {
        setEggState('slightlyCracked');
      }

      return newTaps;
    });
  }, [isHatching, onTap, onHatch, characterType]);

  return (
    <div className="flex flex-col items-center justify-center h-full w-full relative">
      {isHatching && (
        <div className={`text-2xl font-bold mb-4 ${
          isDarkMode ? 'text-white' : 'text-indigo-800'
        } text-center animate-pulse absolute bottom-50 left-0 right-0 z-40`}>
          Peach is evolving!!
        </div>
      )}
      <div className={`text-2xl font-bold mb-4 ${
        isDarkMode ? 'text-white' : 'text-indigo-800'
      } text-center`}>
        {isHatching ? "" : "You have to get through me first!"}
      </div>
      <div
        ref={eggRef}
        onTouchStart={handleTap}
        onMouseDown={handleTap}
        className={`rounded-full w-64 h-64 md:w-80 md:h-80 flex items-center justify-center ${
          isHatching 
            ? 'bg-gray-200' 
            : isDarkMode 
              ? 'bg-gray-700' 
              : evolutionStages[eggState].color
        } transition-all duration-300 shadow-xl overflow-hidden cursor-pointer`}
        style={{ zIndex: 10 }}
      >
        {isHatching ? (
          <video
            ref={videoRef}
            src={eggHatchingVideo}
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
          />
        ) : (
          <img 
            src={evolutionStages[eggState].image} 
            alt="Egg" 
            className="w-5/6 h-5/6 object-contain" 
          />
        )}
      </div>
      <div className={`mt-4 text-lg font-semibold ${
        isDarkMode ? 'text-white' : 'text-indigo-800'
      }`}>
      </div>
      {floatingHearts.map((heart) => (
        <div
          key={heart.id}
          className="absolute text-2xl animate-float-heart z-20"
          style={{
            left: `${heart.x}px`,
            top: `${heart.y}px`,
            '--heart-offset-x': `${heart.offsetX}px`,
          }}
        >
          ❤️
        </div>
      ))}
    </div>
  );

}
//#region Images

const characterImages = {
  kawaii: {
    base: cutebase,
    happy: happypat,
    feed: happyfeed,
    state: happystate,
    angry: happystate, // Placeholder for angry state
  },
  peach: {
    base: peachdefault,
    happy: peachhappy,
    content: peachcontent,
    sad: peachsad,
    angry: peachangry,
    nom1: peachnom1,
    nom2: peachnom2,
    idleAnimation: peachidle,
  },

};


const Character = ({ characterType, currentImage, onTap }) => {
  return (
    <div className="character-container rounded-full w-64 h-64 md:w-80 md:h-80 flex items-center justify-center shadow-xl overflow-visible">
      <img
        src={currentImage}
        alt="Telegotchi"
        className={`character-image w-full h-full object-cover transform ${characterType === 'tsundere' ? 'scale-150' : 'scale-125'}`}
        style={{ marginTop: characterType === 'tsundere' ? '5%' : '-10%' }}
        onTouchStart={onTap}
      />
    </div>
  );
};
//#region SpeechBubble
const SpeechBubble = ({ messages = [], onComplete }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const triggerHapticFeedback = () => {
    if (
      typeof window.Telegram !== 'undefined' &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.HapticFeedback
    ) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    }
  };

  useEffect(() => {
    const validMessages = messages.filter(
      (msg) => typeof msg === 'string' && msg.trim() !== ''
    );

    if (validMessages.length === 0) {
      setIsContentVisible(false);
      if (onComplete) onComplete();
      return;
    }

    let isMounted = true;

    const typeMessage = async () => {
      for (let i = 0; i < validMessages.length; i++) {
        if (!isMounted) break;

        const message = validMessages[i];
        setIsTyping(true);
        setDisplayedText('');
        setIsContentVisible(true);

        for (let j = 0; j < message.length; j++) {
          if (!isMounted) break;
          await new Promise((resolve) => setTimeout(resolve, 25));
          setDisplayedText((prev) => prev + message[j]);
          triggerHapticFeedback();
        }

        setIsTyping(false);

        await new Promise((resolve) => setTimeout(resolve, 3000));

        if (i < validMessages.length - 1) {
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      }

      if (isMounted) {
        setIsContentVisible(false);
        if (onComplete) onComplete();
      }
    };

    typeMessage();

    return () => {
      isMounted = false;
    };
  }, [messages, onComplete]);

  return (
    <div
      className="speech-bubble-container"
      style={{ height: '120px', zIndex: 30 }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isContentVisible ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        className="bg-black rounded-lg p-4 shadow-lg max-w-xs mx-auto mb-4 relative font-geist"
        style={{
          border: '2px solid #4a5568',
          boxShadow:
            '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
          height: '100%',
          overflow: 'hidden',
          background: 'linear-gradient(to bottom, #333333, #111111)',
        }}
      >
        <div
          className="text-gray-200 text-lg font-semibold overflow-y-auto text-center"
          style={{
            height: '100%',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '18px',
            letterSpacing: '0.5px',
            lineHeight: '1.4',
            color: '#e2e8f0',
          }}
        >
          {displayedText}
          {isTyping && <span className="animate-pulse ml-1">|</span>}
        </div>
        <div
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 w-4 h-4 bg-white rotate-45"
          style={{
            border: '2px solid #4a5568',
            borderTop: 'none',
            borderLeft: 'none',
            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
          }}
        ></div>
      </motion.div>
    </div>
  );
};
const AdvancedGlassButton = ({ onClick, icon: Icon }) => {
  return (
    <button 
      onClick={onClick}
      className="relative w-16 h-16 rounded-full focus:outline-none transition-all duration-300 group overflow-hidden border-2 border-black flex items-center justify-center"
    >
      {/* Background layers for glass effect */}
      <div className="absolute inset-0 bg-gradient-to-br from-white/20 to-white/10 rounded-full blur-xl"></div>
      <div className="absolute inset-0 bg-gray-800/30 rounded-full backdrop-filter backdrop-blur-md"></div>
      {/* Icon */}
      <Icon className="relative w-8 h-8 text-white z-10" />
    </button>
  );
};
//#region CharacterStage
const CharacterStage = ({
  stage: initialStage,
  onExpGain,
  onStageChange,
  onTap,
  onCookieBaked,
  characterType,
  exp,
  tapLimit,
  handleOpenStatus,
  handleOpenInvestment,
  handleCloseStatus,
  handleCloseInvestment,
  setTapLimit,
  moodThresholds,
  moodInteractions,
  setOverallMood,
  isDarkMode,
  username,
  setGameState,
  gameState,
  hearts: initialHearts,
  comfort: initialComfort,
  fullness: initialFullness,
  mood: initialMood,
  triggerHapticFeedback,
  speechText,
  setSpeechText,
  maxTapLimit,
  socket
}) => {
  const [stage, setStage] = useState(initialStage);
  const currentImages = characterImages[characterType];
  const [hearts, setHearts] = useState(gameState.hearts || 25);
  const [comfort, setComfort] = useState(gameState.comfort || 50);
  const [fullness, setFullness] = useState(gameState.fullness || 50);
  const [mood, setMood] = useState(gameState.mood || moodStates.NEUTRAL);
  const [floatingHearts, setFloatingHearts] = useState([]);
  const [applePosition, setApplePosition] = useState(null);
  const [isDraggingApple, setIsDraggingApple] = useState(false);
  const [isFeeding, setIsFeeding] = useState(false);
  const [nomTexts, setNomTexts] = useState([]);
  const [lastFed, setLastFed] = useState(Date.now());
  const [heartDeclineInterval, setHeartDeclineInterval] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [idleTime, setIdleTime] = useState(0);
  const [isIdle, setIsIdle] = useState(false);
  const idleAnimationRef = useRef(null);
  const [isPatting, setIsPatting] = useState(false);
  const pattingAnimationRef = useRef(null);
  const [isTapped, setIsTapped] = useState(false);
  const [showSpeechBubble, setShowSpeechBubble] = useState(false);
  const [speechMessages, setSpeechMessages] = useState([]);
  const nom1VideoRef = useRef(null);
  const [isNom1Playing, setIsNom1Playing] = useState(false);
  const [canDragApple, setCanDragApple] = useState(false);
  const growingTimerRef = useRef(null);
  const [isGrowingApple, setIsGrowingApple] = useState(false);
  const dragStartPositionRef = useRef({ x: 0, y: 0 });
  const touchStartRef = useRef({ x: 0, y: 0 });
  const [isTutorialInProgress, setIsTutorialInProgress] = useState(false);
  const [tutorialJustCompleted, setTutorialJustCompleted] = useState(false);
  const [messageQueue, setMessageQueue] = useState([]);
  const messages = {
    relationshipIncrease: (stage) => `Our relationship has grown! We're now ${stage}!`,
    relationshipDecrease: (stage) => `Oh no, our relationship has cooled. We're back to being ${stage}.`,
    levelUp: (level) => `Congratulations! You've reached level ${level}!`,
    gettingHungry: "I'm starting to feel a bit peckish...",
    veryHungry: "I'm really hungry now! Please feed me!",
    gettingUncomfortable: "I'm not feeling very comfortable...",
    veryUncomfortable: "I'm really uncomfortable! Can you help me?",
  };
  const queueMessage = useCallback((message) => {
    setMessageQueue(prevQueue => [...prevQueue, message]);
  }, []);

  useEffect(() => {
    if (idleAnimationRef.current) {
      idleAnimationRef.current.play().catch(e => console.log("Idle animation autoplay prevented"));
    }
    if (pattingAnimationRef.current) {
      pattingAnimationRef.current.play().catch(e => console.log("Patting animation autoplay prevented"));
    }
  }, []);



  const characterRef = useRef(null);
  const appleRef = useRef(null);
  const containerRef = useRef(null);
  const dragOffsetRef = useRef({ x: 0, y: 0 });
  const prevStage = useRef(gameState.relationshipStage);
  const prevExp = useRef(gameState.exp);
  const prevFullness = useRef(gameState.fullness);
  const prevComfort = useRef(gameState.comfort);

  const [touchStartTime, setTouchStartTime] = useState(0);
  const [touchStartPosition, setTouchStartPosition] = useState({ x: 0, y: 0 });
  const [isPat, setIsPat] = useState(false);
  const [lastPatTime, setLastPatTime] = useState(0);
  const PAT_COOLDOWN = 500;
  const NOM1_PLAYBACK_RATE = 2; // Adjust this value to change the speed (1.0 is normal speed)

  const level = Math.floor(exp / 1000) + 1;



  const resetIdleTimer = useCallback(() => {
    setIdleTime(0);
    setIsIdle(false);
    if (idleAnimationRef.current) {
      idleAnimationRef.current.pause();
      idleAnimationRef.current.currentTime = 0;
    }
  }, []);

  useEffect(() => {
    setStage(initialStage);
    setHearts(initialHearts || 25);
    setComfort(initialComfort || 50);
    setFullness(initialFullness || 50);
    setMood(initialMood || moodStates.NEUTRAL);
  }, [initialStage, initialHearts, initialComfort, initialFullness, initialMood]);


  // Add this useEffect to ensure the playback rate is set
  useEffect(() => {
    if (nom1VideoRef.current) {
      nom1VideoRef.current.playbackRate = NOM1_PLAYBACK_RATE;
    }
  }, []);

  const getMaxHeartsForStage = useCallback(() => {
    if (!evolutionStages[stage]) {
      console.error(`Invalid stage: ${stage}`);
      return 100; // Default value
    }
    return evolutionStages[stage].maxHearts || 100; // Fallback to 100 if maxHearts is not defined
  }, [stage]);

  const getCriticalRegionThreshold = useCallback(() => {
    const maxHearts = (() => {
      switch (stage) {
        case 'Stranger': return 100;
        case 'Acquaintance': return 200;
        case 'Friend': return 500;
        case 'CloseFriend': return 1000;
        case 'BestFriend': return 2000;
        case 'Crush': return 4000;
        case 'Girlfriend': return 10000;
        case 'Fiancee': return 25000;
        case 'Waifu': return 50000;
        default: return 10000;
      }
    })();
    return Math.floor(maxHearts * 0.9);
  }, [stage]);

  const updateMood = useCallback(() => {
    let newMood = moodStates.NEUTRAL;
    for (const [moodState, threshold] of Object.entries(moodThresholds)) {
      if (comfort >= threshold.comfort && fullness >= threshold.fullness) {
        newMood = moodState;
        break;
      }
    }
    setMood(newMood);
    
    // Update the gameState with the new mood
    setGameState(prevState => ({
      ...prevState,
      mood: newMood
    }));
  
    if (heartDeclineInterval) {
      clearInterval(heartDeclineInterval);
      setHeartDeclineInterval(null);
    }
    if (newMood === moodStates.NEUTRAL) {
      const interval = setInterval(() => {
        setHearts(prev => Math.max(0, prev - 1));
      }, 60000);
      setHeartDeclineInterval(interval);
    }
  }, [comfort, fullness, heartDeclineInterval, moodThresholds,setGameState]);


  useEffect(() => {
    updateMood();
  }, [comfort, fullness, updateMood]);

  const attemptUpgrade = useCallback(() => {
    const currentStage = evolutionStages[stage];
    if (!currentStage) {
      console.error(`Invalid current stage: ${stage}`);
      return;
    }
    
    const stageEntries = Object.entries(evolutionStages);
    const currentIndex = stageEntries.findIndex(([key]) => key === stage);
    const nextStageEntry = stageEntries[currentIndex + 1];
    
    if (nextStageEntry) {
      const [nextStageName, nextStage] = nextStageEntry;
      const timeSinceLastFed = Date.now() - lastFed;
      const isWellFed = timeSinceLastFed < WELL_FED_DURATION;
  
      if (exp >= nextStage.expNeeded && isWellFed && hearts >= getCriticalRegionThreshold()) {
        const upgradeChance = 0.1 * (mood === moodStates.HAPPY ? 1.5 : 1);
        if (Math.random() < upgradeChance) {
          onStageChange(nextStageName);
          setHearts(25);
          setStage(nextStageName);
          triggerHapticFeedback('upgrade');
        }
      }
    }
  }, [stage, exp, lastFed, hearts, mood, onStageChange, getCriticalRegionThreshold]);

  const attemptDowngrade = useCallback(() => {
    if (hearts <= 10) {
      const currentStage = evolutionStages[stage];
      const prevStage = Object.values(evolutionStages).reverse().find(s => s.expNeeded < currentStage.expNeeded);
      if (prevStage) {
        const downgradeProbability = hearts === 0 ? 1 : (10 - hearts + 1) * 0.05;
        if (Math.random() < downgradeProbability) {
          onStageChange(prevStage.name);
          setHearts(25);
          triggerHapticFeedback('downgrade');
        }
      }
    }
  }, [hearts, stage, onStageChange]);

  const handleInteraction = useCallback((interactionType) => {
    const energyCost = ENERGY_COSTS[interactionType.toUpperCase()] || 0;
    
    if (gameState.tapLimit < energyCost) {
      // Not enough energy, don't perform the action
      return;
    }
  
    setGameState(prevState => {
      const newTapLimit = Math.max(0, prevState.tapLimit - energyCost);
      
      // Rest of your existing logic for handling interactions
      const interaction = moodInteractions[mood][interactionType] || moodInteractions.NEUTRAL[interactionType];
      const maxHearts = getMaxHeartsForStage(prevState.relationshipStage);
      const isFull = fullness > 80;
  
      const newHearts = Math.min(maxHearts, hearts + interaction.heartGain + (isFull && interactionType === 'tap' ? 2 : 0));
      const newComfort = interactionType === 'pat' 
        ? Math.min(100, comfort + (interaction.comfortGain || 0))
        : comfort;
      const newFullness = Math.min(100, fullness + (interaction.fullnessGain || 0));
      const newExp = prevState.exp + interaction.expGain;
  
      let newMood = mood;
      for (const [moodState, threshold] of Object.entries(moodThresholds)) {
        if (newComfort >= threshold.comfort && newFullness >= threshold.fullness) {
          newMood = moodState;
          break;
        }
      }
      setMood(newMood);
  
      let newOverallMood = prevState.overallMood;
      if (newMood === moodStates.ECSTATIC || newMood === moodStates.HAPPY || newMood === moodStates.CONTENT) {
        newOverallMood = 'happy';
      } else if (newMood === moodStates.SAD || newMood === moodStates.UPSET) {
        newOverallMood = 'sad';
      } else if (newMood === moodStates.ANGRY) {
        newOverallMood = 'angry';
      } else {
        newOverallMood = 'neutral';
      }
  
      // Attempt upgrade
      let newRelationshipStage = prevState.relationshipStage;
      const currentStage = evolutionStages[prevState.relationshipStage];
      const stageEntries = Object.entries(evolutionStages);
      const currentIndex = stageEntries.findIndex(([key]) => key === prevState.relationshipStage);
      const nextStageEntry = stageEntries[currentIndex + 1];
      
      if (nextStageEntry) {
        const [nextStageName, nextStage] = nextStageEntry;
        const timeSinceLastFed = Date.now() - prevState.lastFed;
        const isWellFed = timeSinceLastFed < WELL_FED_DURATION;
    
        if (newExp >= nextStage.expNeeded && isWellFed && newHearts >= getCriticalRegionThreshold(prevState.relationshipStage)) {
          const upgradeChance = 0.1 * (newMood === moodStates.HAPPY ? 1.5 : 1);
          if (Math.random() < upgradeChance) {
            newRelationshipStage = nextStageName;
            triggerHapticFeedback('upgrade');
          }
        }
      }
  
      // Attempt downgrade
      if (newHearts <= 10) {
        const prevStage = Object.values(evolutionStages).reverse().find(s => s.expNeeded < currentStage.expNeeded);
        if (prevStage) {
          const downgradeProbability = newHearts === 0 ? 1 : (10 - newHearts + 1) * 0.05;
          if (Math.random() < downgradeProbability) {
            newRelationshipStage = prevStage.name;
            triggerHapticFeedback('downgrade');
          }
        }
      }
      
      // WebSocket or HTTP fallback logic
      const updatedState = {
        ...prevState,
        tapLimit: newTapLimit,
        hearts: newHearts,
        comfort: newComfort,
        fullness: newFullness,
        exp: newExp,
        mood: newMood,
        overallMood: newOverallMood,
        relationshipStage: newRelationshipStage,
        lastFed: interactionType === 'feed' ? Date.now() : prevState.lastFed,
      };
      
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({
          type: 'interaction',
          interactionType: interactionType,
          username: username,
          gameState: updatedState
        }));
      } else {
        // Fallback to HTTP if WebSocket is not available
        fetch('/api/updateUserData', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username: username, gameState: updatedState }),
        }).catch(error => console.error('Error updating game state:', error));
      }
  
      // Update local state
      setHearts(newHearts);
      setComfort(newComfort);
      setFullness(newFullness);
  
      return updatedState;
    });
  
    if (interactionType === 'feed') {
      triggerHapticFeedback('feed');
    } else if (interactionType === 'tap') {
      triggerHapticFeedback('tap');
    }
  }, [gameState, setGameState, hearts, comfort, fullness, mood, getMaxHeartsForStage, triggerHapticFeedback,socket, username]);

  useEffect(() => {
    // Relationship stage change
    if (prevStage.current !== gameState.relationshipStage) {
      if (evolutionStages[gameState.relationshipStage].expNeeded > evolutionStages[prevStage.current].expNeeded) {
        queueMessage(messages.relationshipIncrease(gameState.relationshipStage));
      } else {
        queueMessage(messages.relationshipDecrease(gameState.relationshipStage));
      }
      prevStage.current = gameState.relationshipStage;
    }
  
    // Level up
    const currentLevel = Math.floor(gameState.exp / 1000) + 1;
    const prevLevel = Math.floor(prevExp.current / 1000) + 1;
    if (currentLevel > prevLevel) {
      queueMessage(messages.levelUp(currentLevel));
    }
    prevExp.current = gameState.exp;
  
    // Hunger
    if (gameState.fullness <= 30 && prevFullness.current > 30) {
      queueMessage(messages.gettingHungry);
    } else if (gameState.fullness <= 10 && prevFullness.current > 10) {
      queueMessage(messages.veryHungry);
    }
    prevFullness.current = gameState.fullness;
  
    // Comfort
    if (gameState.comfort <= 30 && prevComfort.current > 30) {
      queueMessage(messages.gettingUncomfortable);
    } else if (gameState.comfort <= 10 && prevComfort.current > 10) {
      queueMessage(messages.veryUncomfortable);
    }
    prevComfort.current = gameState.comfort;
  
  }, [gameState, queueMessage]);

  const spawnApple = useCallback(() => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const appleSize = 45; // Adjust this value based on your apple size
      const x = Math.random() * (containerRect.width - appleSize);
      const y = Math.random() * (containerRect.height - appleSize);
      setApplePosition({ x, y });
    }
  }, []);


  useEffect(() => {
    spawnApple();
    
    const comfortInterval = setInterval(() => {
      setComfort(prev => Math.max(0, prev - 1));
    }, 60000);
  
    const fullnessInterval = setInterval(() => {
      setFullness(prev => Math.max(0, prev - 2));
    }, 60000); 
  
    const heartsInterval = setInterval(() => {
      setHearts(prev => Math.max(0, prev - 1));
    }, 10000); 
  
    const moodInterval = setInterval(updateMood, 60000);
  
    return () => {
      clearInterval(comfortInterval);
      clearInterval(fullnessInterval);
      clearInterval(heartsInterval);
      clearInterval(moodInterval);
    };
  }, [spawnApple, updateMood]);

  useEffect(() => {
    setCurrentImage(characterImages[characterType]?.base || Object.values(characterImages[characterType])[0]);
  }, [characterType]);
  
  const handleTap = useCallback((e) => {
    if (e.type.startsWith('touch') && !isDraggingApple && gameState.tapLimit >= ENERGY_COSTS.TAP) {
      e.preventDefault();
      
      setGameState(prevState => ({
        ...prevState,
        taps: prevState.taps + 1,
        tapLimit: Math.max(0, prevState.tapLimit - ENERGY_COSTS.TAP),
      }));
    
      handleInteraction('tap');
      triggerHapticFeedback('tap');
      setIsTapped(true);

      if (characterRef.current) {
        characterRef.current.classList.add('wiggle');
        setTimeout(() => characterRef.current.classList.remove('wiggle'), 500);
      }
      
      setTimeout(() => {
        setIsTapped(false);
      }, 100);
    }
  }, [setGameState, handleInteraction, triggerHapticFeedback, isDraggingApple, gameState.tapLimit]);
  
  const handleTouchStart = useCallback((e) => {
    if (isDraggingApple) return;
    resetIdleTimer();
    setTouchStartTime(Date.now());
    const touch = e.touches[0];
    setTouchStartPosition({ x: touch.clientX, y: touch.clientY });
    setIsPat(false);
    setIsPatting(false);

    // Trigger wiggle animation immediately
    if (characterRef.current) {
      characterRef.current.classList.add('wiggle');
    }

    // Trigger haptic feedback
    if (typeof WebApp !== 'undefined' && WebApp.HapticFeedback) {
      WebApp.HapticFeedback.impactOccurred('medium');
    }
  }, [isDraggingApple, resetIdleTimer]);

  const handleTouchMove = useCallback((e) => {
    resetIdleTimer();
    if (isDraggingApple) return;
  
    const now = Date.now();
    if (now - lastPatTime < PAT_COOLDOWN) return;
  
    const touch = e.touches[0];
    const deltaX = touch.clientX - touchStartPosition.x;
    const deltaY = touch.clientY - touchStartPosition.y;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  
    if (distance > 10 && gameState.tapLimit >= ENERGY_COSTS.PAT) {
      setIsPatting(true);
      setIsPat(true);
      setIsIdle(false);
      handleInteraction('pat');
      setLastPatTime(now);
    }
  }, [isDraggingApple, touchStartPosition, resetIdleTimer, handleInteraction, lastPatTime, gameState.tapLimit]);
  
  const handleTouchEnd = useCallback((e) => {
    resetIdleTimer();
    setIsPatting(false);
    if (isDraggingApple) return;

    const touchDuration = Date.now() - touchStartTime;

    if (!isPat && touchDuration < 200 && gameState.tapLimit >= ENERGY_COSTS.TAP) {
      handleTap(e);
    }

    // Remove wiggle class after animation completes
    setTimeout(() => {
      if (characterRef.current) {
        characterRef.current.classList.remove('wiggle');
      }
    }, 500); // Adjust this timing to match your wiggle animation duration

    const rect = e.target.getBoundingClientRect();
    const heartX = e.changedTouches[0].clientX - rect.left;
    const heartY = e.changedTouches[0].clientY - rect.top;
    const newHeart = {
      id: Date.now(),
      x: heartX,
      y: heartY,
      offsetX: Math.random() * 40 - 20,
      offsetY: -50 - Math.random() * 50,
    };
    setFloatingHearts((prev) => [...prev, newHeart]);
    setTimeout(() => setFloatingHearts((prev) => prev.filter((heart) => heart.id !== newHeart.id)), 1000);

    setIsPat(false);
  }, [isDraggingApple, touchStartTime, resetIdleTimer, isPat, handleTap, gameState.tapLimit]);

  useEffect(() => {
    console.log('Tutorial effect running');
    console.log('gameState.tutorialShown:', gameState.tutorialShown);
    console.log('isTutorialInProgress:', isTutorialInProgress);
  
    if (gameState.gameStage === 'character' && !gameState.tutorialShown && !isTutorialInProgress) {
      console.log('Starting tutorial');
      const tutorialMessages = [
        "Welcome to MomoWars!",
        "Tap me to increase my heart meter.",
        "Drag your finger on my head to pat me!",
        "Don't forget to feed me the dangos that appear.",
      ];
      setSpeechMessages(tutorialMessages);
      setShowSpeechBubble(true);
      setIsTutorialInProgress(true);
    } else if (gameState.gameStage === 'character' && gameState.tutorialShown && !tutorialJustCompleted) {
      console.log('Showing welcome back message');
      const welcomeMessage = [`Welcome back, ${username}!`];
      setSpeechMessages(welcomeMessage);
      setShowSpeechBubble(true);
  
      const timer = setTimeout(() => {
        setShowSpeechBubble(false);
      }, welcomeMessage[0].length * 25 + 3000);
  
      return () => clearTimeout(timer);
    }
  }, [gameState.gameStage, gameState.tutorialShown, username, isTutorialInProgress, tutorialJustCompleted]);
  const handleTutorialComplete = useCallback(() => {
    console.log('Tutorial completed');
    setIsTutorialInProgress(false);
    setTutorialJustCompleted(true);
    setGameState((prevState) => {
      console.log('Setting tutorialShown to true');
      return {
        ...prevState,
        tutorialShown: true,
      };
    });
  }, [setGameState]);
  useEffect(() => {
    return () => {
      setTutorialJustCompleted(false);
    };
  }, []);
  useEffect(() => {
    const preventScroll = (e) => {
      if (isDraggingApple || isPatting) {
        e.preventDefault();
      }
    };
  
    document.addEventListener('touchmove', preventScroll, { passive: false });
  
    return () => {
      document.removeEventListener('touchmove', preventScroll);
    };
  }, [isDraggingApple, isPatting]);

  const handleAppleTouchStart = useCallback((e) => {
    e.preventDefault();
    resetIdleTimer();
    const touch = e.touches[0];
    touchStartRef.current = { x: touch.clientX, y: touch.clientY };

    setIsGrowingApple(true);
    setIsNom1Playing(true);
    if (nom1VideoRef.current) {
      nom1VideoRef.current.currentTime = 0;
      nom1VideoRef.current.playbackRate = NOM1_PLAYBACK_RATE;
      nom1VideoRef.current.play().catch(e => console.log("Nom1 animation play prevented:", e));
    }

    growingTimerRef.current = setTimeout(() => {
      setIsGrowingApple(false);
      setIsDraggingApple(true);
      
      if (typeof WebApp !== 'undefined' && WebApp.HapticFeedback) {
        WebApp.HapticFeedback.notificationOccurred('success');
      }
    }, 250);
  }, [resetIdleTimer, NOM1_PLAYBACK_RATE]);

  const handleAppleTouchMove = useCallback((e) => {
    e.preventDefault();
    if (!isDraggingApple) return;

    const touch = e.touches[0];
    const containerRect = containerRef.current.getBoundingClientRect();
    const appleRect = appleRef.current.getBoundingClientRect();

    // Calculate the distance between the touch point and the apple's center
    const appleCenterX = appleRect.left + appleRect.width / 2;
    const appleCenterY = appleRect.top + appleRect.height / 2;
    const distance = Math.sqrt(
      Math.pow(touch.clientX - appleCenterX, 2) + 
      Math.pow(touch.clientY - appleCenterY, 2)
    );

    // Only move the apple if the finger is close enough (e.g., within 100 pixels)
    if (distance <= 100) {
      let newX = touch.clientX - containerRect.left - appleRect.width / 2;
      let newY = touch.clientY - containerRect.top - appleRect.height / 2;

      // Add boundaries
      newX = Math.max(0, Math.min(newX, containerRect.width - appleRect.width));
      newY = Math.max(0, Math.min(newY, containerRect.height - appleRect.height));

      setApplePosition({ x: newX, y: newY });
    }
  }, [isDraggingApple]);

  const handleAppleTouchEnd = useCallback((e) => {
    e.preventDefault();
    resetIdleTimer();
    
    if (growingTimerRef.current) {
      clearTimeout(growingTimerRef.current);
      growingTimerRef.current = null;
    }

    setIsDraggingApple(false);
    setIsGrowingApple(false);
    setIsNom1Playing(false);
    
    if (nom1VideoRef.current) {
      nom1VideoRef.current.pause();
      nom1VideoRef.current.currentTime = 0;
    }

    if (characterRef.current && appleRef.current) {
      const characterRect = characterRef.current.getBoundingClientRect();
      const appleRect = appleRef.current.getBoundingClientRect();

      if (
        appleRect.left < characterRect.right &&
        appleRect.right > characterRect.left &&
        appleRect.top < characterRect.bottom &&
        appleRect.bottom > characterRect.top &&
        gameState.tapLimit >= ENERGY_COSTS.FEED
      ) {
        handleInteraction('feed');
        const cookiesProduced = Math.floor(Math.random() * 3) + 1;
        onCookieBaked(cookiesProduced);
        setLastFed(Date.now());
        triggerHapticFeedback('feed');

        const newNom = { id: Date.now(), x: 50, y: 50 };
        setNomTexts((prev) => [...prev, newNom]);
        
        spawnApple();

        setTimeout(() => {
          setNomTexts((prev) => prev.filter((nom) => nom.id !== newNom.id));
        }, 1000);
      }
    }
  }, [
    resetIdleTimer,
    handleInteraction,
    onCookieBaked,
    gameState.tapLimit,
    spawnApple,
    triggerHapticFeedback,
    setLastFed,
    setNomTexts
  ]);



  useEffect(() => {
    const handleVideoEnded = () => {
      if (isDraggingApple && nom1VideoRef.current) {
        nom1VideoRef.current.pause();
        // Set the current time to slightly before the end to show the last frame
        nom1VideoRef.current.currentTime = nom1VideoRef.current.duration - 0.001;
      }
    };
  
    const videoElement = nom1VideoRef.current;
    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnded);
    }
  
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, [isDraggingApple]);

  useEffect(() => {
    const preventScroll = (e) => {
      if (isDraggingApple) {
        e.preventDefault();
      }
    };
  
    document.addEventListener('touchmove', preventScroll, { passive: false });
  
    return () => {
      document.removeEventListener('touchmove', preventScroll);
    };
  }, [isDraggingApple]);




  const getMoodText = useCallback(() => {
    console.log('Current mood:', mood);
    return moodStates[mood] || 'Neutral';
  }, [mood]);

  const getMoodClass = useCallback(() => {
    switch (mood) {
      case moodStates.ECSTATIC:
        return 'ecstatic';
      case moodStates.HAPPY:
        return 'happy';
      case moodStates.CONTENT:
        return 'content';
      case moodStates.NEUTRAL:
        return 'neutral';
      case moodStates.BORED:
        return 'bored';
      case moodStates.HUNGRY:
        return 'hungry';
      case moodStates.SAD:
        return 'sad';
      case moodStates.UPSET:
        return 'sad'; // Using 'sad' for upset as well
      case moodStates.ANGRY:
        return 'angry';
      default:
        return 'neutral';
    }
  }, [mood]);

 
  useEffect(() => {
    if (speechText) {
      setShowSpeechBubble(true);
    }
  }, [speechText]);

  const handleSpeechComplete = useCallback(() => {
    setShowSpeechBubble(false);
    if (messageQueue.length > 0) {
      setSpeechMessages([messageQueue[0]]);
      setShowSpeechBubble(true);
      setMessageQueue(prevQueue => prevQueue.slice(1));
    }
  }, [messageQueue]);

  useEffect(() => {
    let idleTimer;
    const IDLE_THRESHOLD = 5000; // 30 seconds of inactivity

    const resetIdleTimer = () => {
      setIdleTime(0);
      setIsIdle(false);
      if (idleAnimationRef.current) {
        idleAnimationRef.current.pause();
        idleAnimationRef.current.currentTime = 0;
      }
    };

    const incrementIdleTime = () => {
      setIdleTime(prevTime => {
        if (prevTime + 1000 >= IDLE_THRESHOLD) {
          setIsIdle(true);
          if (idleAnimationRef.current) {
            idleAnimationRef.current.play();
          }
          return IDLE_THRESHOLD;
        }
        return prevTime + 1000;
      });
    };

    idleTimer = setInterval(incrementIdleTime, 1000);

    // Reset idle timer on any interaction
    const interactionEvents = ['touchstart', 'touchmove', 'touchend'];
    interactionEvents.forEach(event => {
      document.addEventListener(event, resetIdleTimer);
    });

    return () => {
      clearInterval(idleTimer);
      interactionEvents.forEach(event => {
        document.removeEventListener(event, resetIdleTimer);
      });
    };
  }, []);
//#region CReturn

return (
  <div
    ref={containerRef}
    className={`flex flex-col items-center justify-center h-full w-full relative overflow-hidden z-10 ${
      isDarkMode ? 'dark-mode' : 'light-mode'
    } font-geist`}
  >
    <div
      className="speech-bubble-container"
      style={{
        touchAction: isDraggingApple || isPatting ? 'none' : 'auto',
        height: '90px',
      }}
    >
      <SpeechBubble
        messages={showSpeechBubble ? speechMessages : []}
        onComplete={isTutorialInProgress ? handleTutorialComplete : handleSpeechComplete}
      />
    </div>
    <div className="text-2xl font-bold mb-4 text-indigo-800 text-center z-9">
      {getMoodText()}
    </div>
    <div className="relative w-full h-full flex justify-center items-center">
      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-4 h-64 bg-gray-200 rounded-full overflow-hidden">
        <div
          className="w-full bg-green-500 rounded-full transition-all duration-300"
          style={{ height: `${comfort}%`, position: 'absolute', bottom: 0 }}
        ></div>
      </div>
      <div className="absolute right-0 top-1/2 transform -translate-y-1/2 w-4 h-64 bg-gray-200 rounded-full overflow-hidden">
        <div
          className="w-full bg-blue-500 rounded-full transition-all duration-300"
          style={{ height: `${fullness}%`, position: 'absolute', bottom: 0 }}
        ></div>
      </div>
      <div
        ref={characterRef}
        className={`character-container flex items-center justify-center transition-all duration-300 cursor-pointer ${getMoodClass()} ${mood === moodStates.HUNGRY ? 'pulsate' : ''} ${isIdle ? 'idle' : ''} ${isPatting ? 'patting' : ''} ${isTapped ? 'tapped' : ''} ${isNom1Playing ? 'nomming' : ''}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="character-inner-container">
          <video
            ref={idleAnimationRef}
            src={currentImages.idleAnimation}
            loop
            muted
            playsInline
            className="idle-animation"
          />
          <video
            ref={pattingAnimationRef}
            src={peachPattingAnimation}
            loop
            muted
            playsInline
            className="patting-animation"
          />
          <video
            ref={nom1VideoRef}
            src={currentImages.nom1}
            playsInline
            muted
            className={`w-full h-full object-cover nom1-animation`}
          />
          <img
            src={currentImages[mood.toLowerCase()] || currentImages.base}
            alt="Character"
            className="default-state"
          />
        </div>
      </div>
    </div>
    <div className="mt-4 text-lg flex flex-col items-center z-10">
  <div className="flex items-center mb-2 bg-white bg-opacity-80 rounded-full px-4 py-2 shadow-md">
    <Heart className="text-red-500 mr-2" size={24} />
    <span className="text-red-600 font-bold text-stroke-sm">
      {Math.floor(hearts)}
    </span>
  </div>
  <div className="flex items-center mb-2 bg-white bg-opacity-80 rounded-full px-4 py-2 shadow-md">
    <span className="mr-4 font-semibold">
      <span className="text-green-600">Comfort:</span>{' '}
      <span className="text-green-700 text-stroke-sm">{comfort}</span>
    </span>
    <span className="font-semibold">
      <span className="text-blue-600">Fullness:</span>{' '}
      <span className="text-blue-700 text-stroke-sm">{fullness}</span>
    </span>
  </div>
  <div className="text-sm font-bold bg-indigo-600 text-white px-4 py-2 rounded-full mb-2 shadow-md">
    {stage} - Level {Math.floor(exp / 1000) + 1}
  </div>
  <div className="bg-white rounded-full shadow-md px-4 py-2 flex items-center mt-2">
    <Battery className="text-indigo-600 mr-2" size={20} />
    <span className="text-indigo-600 font-semibold">
      {tapLimit} / {maxTapLimit}
    </span>
  </div>
</div>
    {applePosition && (
      <div
        ref={appleRef}
        className={`apple absolute ${isGrowingApple ? 'apple-growing' : isDraggingApple ? 'apple-grown' : ''}`}
        style={{
          left: `${applePosition.x}px`,
          top: `${applePosition.y}px`,
          width: '45px',
          height: '45px',
          touchAction: 'none',
          transition: isDraggingApple ? 'none' : 'transform 0.5s ease-out',
          zIndex: 40,
        }}
        onTouchStart={handleAppleTouchStart}
        onTouchMove={handleAppleTouchMove}
        onTouchEnd={handleAppleTouchEnd}
      >
        <img src={appleImage} alt="Apple" className="w-full h-full object-contain pointer-events-none" />
      </div>
    )}
    {floatingHearts.map((heart) => (
      <div
        key={heart.id}
        className="absolute text-2xl animate-float-heart z-30"
        style={{
          left: `${heart.x}px`,
          top: `${heart.y}px`,
          '--heart-offset-x': `${heart.offsetX}px`,
          '--heart-offset-y': `${heart.offsetY}px`,
        }}
      >
        ❤️
      </div>
    ))}
    {nomTexts.map((nom) => (
      <div key={nom.id} className="absolute text-2xl font-bold text-green-500 animate-fade-out z-999" style={{ left: `${nom.x}%`, top: `${nom.y}%` }}>
        Nom!
      </div>
    ))}
    <div className="fixed bottom-16 left-4 z-10">
      <AdvancedGlassButton onClick={handleOpenStatus} icon={User} />
    </div>
    <div className="fixed bottom-16 right-4 z-10">
      <AdvancedGlassButton onClick={handleOpenInvestment} icon={Home} />
    </div>
  </div>
);
}
//#region Status

function StatusScreen({ 
  onClose, 
  username,
  gameState,
  getMinimumTapsForUpgrade,
  getCriticalRegionThreshold
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.onEvent('backButtonClicked', onClose);
    }

    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.offEvent('backButtonClicked', onClose);
      }
    };
  }, [onClose]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const { 
    relationshipStage: stage = 'Stranger', 
    exp = 0, 
    cookieBalance = 0, 
    selectedCharacter: characterType = 'peach',
    hearts = 0,
    comfort = 0,
    fullness = 0,
    mood = 'NEUTRAL',
    taps = 0,
    efficiency = 1, // This is the user's efficiency from gameState
  } = gameState;

  const level = Math.floor(exp / 1000) + 1;
  const expPercentage = ((exp % 1000) / 1000) * 100;

  // Map character types to their respective images
  const characterImages = {
    peach: peachstatus
    // Add other character types and their corresponding images
  };

  const StatusBar = ({ value, label, icon }) => (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-1">
        {icon}
        <span className="text-sm font-medium">{label}</span>
        <span className="text-sm font-bold">{value}%</span>
      </div>
      <div className="w-full bg-gray-700 rounded-full h-2">
        <div 
          className="bg-blue-500 h-2 rounded-full transition-all duration-300" 
          style={{width: `${value}%`}}
        ></div>
      </div>
    </div>
  );

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 bg-black text-white overflow-y-auto z-50 font-geist"
        >
          <div className="container mx-auto max-w-md p-6">
            <h2 className="text-3xl font-bold mb-8 text-center">Momo-chan Status</h2>

            {/* Character Portrait */}
            <div className="mb-8 bg-black w-full">
              <img 
                src={characterImages[characterType]} 
                alt="Character" 
                className="w-full h-auto object-contain"
              />
            </div>

            {/* Exp Bar */}
            <div className="mb-6 bg-gray-900 rounded-lg p-4">
              <div className="flex justify-between items-center mb-2">
                <span>Level {level}</span>
                <span>{exp % 1000}/{1000} EXP</span>
              </div>
              <div className="w-full bg-gray-700 rounded-full h-2 mb-4">
                <div 
                  className="bg-blue-500 h-2 rounded-full transition-all duration-300" 
                  style={{width: `${expPercentage}%`}}
                ></div>
              </div>
              <div className="flex items-center text-yellow-400">
                <Star className="mr-2" size={20} />
                <span className="font-medium">Relationship: {stage}</span>
              </div>
            </div>

            {/* Status Info */}
            <div className="bg-gray-900 rounded-lg p-4">
              <table className="w-full">
                <tbody>
                  <tr className="border-b border-gray-800">
                    <td className="py-2"><TrendingUp className="inline mr-2" size={18} /> Total EXP</td>
                    <td className="py-2 text-right">{exp}</td>
                  </tr>
                  <tr className="border-b border-gray-800">
                    <td className="py-2"><Heart className="inline mr-2" size={18} /> Hearts</td>
                    <td className="py-2 text-right">{hearts}</td>
                  </tr>
                  <tr className="border-b border-gray-800">
                    <td className="py-2"><Cookie className="inline mr-2" size={18} /> Cookies Baked</td>
                    <td className="py-2 text-right">{cookieBalance}</td>
                  </tr>
                  <tr className="border-b border-gray-800">
                    <td className="py-2">Comfort</td>
                    <td className="py-2 text-right">{comfort}%</td>
                  </tr>
                  <tr className="border-b border-gray-800">
                    <td className="py-2">Fullness</td>
                    <td className="py-2 text-right">{fullness}%</td>
                  </tr>
                  <tr className="border-b border-gray-800">
                    <td className="py-2">Mood</td>
                    <td className="py-2 text-right">{mood}</td>
                  </tr>
                  <tr className="border-b border-gray-800">
                    <td className="py-2"><Activity className="inline mr-2" size={18} /> User Efficiency</td>
                    <td className="py-2 text-right">{(efficiency * 100).toFixed(2)}%</td>
                    </tr>
                  <tr>
                    <td className="py-2"><Zap className="inline mr-2" size={18} /> Total Taps</td>
                    <td className="py-2 text-right">{taps}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Status Bars */}
            <div className="mt-6">
              <StatusBar value={comfort} label="Comfort" icon={<Smile className="text-blue-400" size={18} />} />
              <StatusBar value={fullness} label="Fullness" icon={<Coffee className="text-green-400" size={18} />} />
              <StatusBar value={hearts} label="Hearts" icon={<Heart className="text-red-400" size={18} />} />
            </div>

            {/* Upgrade Info */}
            <div className="mt-6 bg-gray-900 rounded-lg p-4">
              <h3 className="text-xl font-bold mb-2">Next Upgrade</h3>
              <p className="text-sm text-gray-300 mb-2">
                Minimum taps for upgrade: {getMinimumTapsForUpgrade(stage)}
              </p>
              <p className="text-sm text-gray-300">
                Critical region threshold: {getCriticalRegionThreshold(stage)}
              </p>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
// #region Investment
const InvestmentScreen = ({ 
  onClose, 
  isDarkMode, 
  username, 
  gameState, 
  setGameState, 
  socket, 
}) => {
  const [activeTab, setActiveTab] = useState('bakery');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [isBuying, setIsBuying] = useState(true);
  const [portfolio, setPortfolio] = useState({ ownedShares: [] });
  const [priceHistory, setPriceHistory] = useState([]);
  const [newBakeryName, setNewBakeryName] = useState('');
  const [isCreatingBakery, setIsCreatingBakery] = useState(false);
  const [isFetchingBakeryInfo, setIsFetchingBakeryInfo] = useState(false);
  const [shouldFetchBakeryInfo, setShouldFetchBakeryInfo] = useState(true);
  const [lastCheckIn, setLastCheckIn] = useState(null);
  const [nextCheckIn, setNextCheckIn] = useState(null);
  const [checkInAvailable, setCheckInAvailable] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [bakeryInfo, setBakeryInfo] = useState(null);
  const [isLoadingPortfolio, setIsLoadingPortfolio] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [shareAmountInput, setShareAmountInput] = useState('1'); // For the input field
  const [bakeryNameError, setBakeryNameError] = useState('');
  const [unclaimedCookies, setUnclaimedCookies] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState({ hours: 0, minutes: 0 });

  const [latestBuyPrice, setLatestBuyPrice] = useState(null);
  const [latestSellPrice, setLatestSellPrice] = useState(null);
  const [searchType, setSearchType] = useState('bakery');
  const cookieBalance = useMemo(() => Math.round(gameState?.cookieBalance || 0), [gameState?.cookieBalance]);
  const [shareAmount, setShareAmount] = useState(1);
  const [totalCost, setTotalCost] = useState(0);

  const MAX_SHARE_AMOUNT = 1000; // Define a reasonable upper limit

  useEffect(() => {
    if (selectedUser) {
      const price = isBuying ? (latestBuyPrice || selectedUser.bakeryPriceInfo.sharePrice) : (latestSellPrice || selectedUser.bakeryPriceInfo.sellPrice);
      setTotalCost(price * shareAmount);
    }
  }, [shareAmount, isBuying, selectedUser, latestBuyPrice, latestSellPrice]);

  useEffect(() => {
    if (bakeryInfo) {
      setUnclaimedCookies(bakeryInfo.unclaimedCookies || 0);
    }
  }, [bakeryInfo]);

// Inside the InvestmentScreen component
const handleClaimCookies = useCallback(async () => {
  if (isProcessing) return;
  setIsProcessing(true);
  try {
    const response = await fetch('/api/cookies/claim', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    if (result.success) {
      setGameState(prevState => ({
        ...prevState,
        cookieBalance: result.newBalance,
        unclaimedCookies: 0, // Set unclaimed cookies to 0
      }));
      alert(`Successfully claimed ${result.claimedAmount} cookies! Your new balance is ${result.newBalance} 🍪.`);
    } else {
      throw new Error(result.error || 'Failed to claim cookies.');
    }
  } catch (error) {
    console.error('Error claiming cookies:', error);
    alert(`Error: ${error.message}`);
  } finally {
    setIsProcessing(false);
  }
}, [username, isProcessing, setGameState]);

  useEffect(() => {
    if (typeof gameState === 'undefined') {
      console.warn('gameState is undefined in InvestmentScreen');
    }
  }, [gameState]);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'hidden';
    };
  }, []);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.onEvent('backButtonClicked', onClose);
    }

    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.offEvent('backButtonClicked', onClose);
      }
    };
  }, [onClose]);

  useEffect(() => {
    if (gameState) {
      const now = new Date();
      const lastCheckInDate = gameState.lastCheckIn ? new Date(gameState.lastCheckIn) : null;
      const nextCheckInDate = lastCheckInDate ? new Date(lastCheckInDate.getTime() + 24 * 60 * 60 * 1000) : now;
      
      setLastCheckIn(lastCheckInDate);
      setNextCheckIn(nextCheckInDate);
      setCheckInAvailable(now >= nextCheckInDate);
    }
  }, [gameState]);

  const fetchPortfolio = useCallback(async () => {
    setIsLoadingPortfolio(true);
    try {
      const response = await fetch(`/api/user/portfolio?username=${username}`);
      if (!response.ok) {
        throw new Error('Failed to fetch portfolio data');
      }
      const data = await response.json();
      setPortfolio(data);
    } catch (error) {
      console.error('Error fetching portfolio:', error);
      // Consider adding a user-friendly error message here
    } finally {
      setIsLoadingPortfolio(false);
    }
  }, [username]);
  

  useEffect(() => {
    fetchPortfolio();
  }, [fetchPortfolio]);

  const calculateTotalValue = useCallback((ownedShares) => {
    return ownedShares.reduce((total, share) => {
      return total + (share.shareCount * share.sharePrice);
    }, 0);
  }, []);
  
  
  

  const fetchBakeryInfo = useCallback(async () => {
    try {
      const response = await fetch(`/api/bakery/info?username=${username}`);
      if (!response.ok) {
        throw new Error('Failed to fetch bakery info');
      }
      const data = await response.json();
      setGameState(prevState => ({
        ...prevState,
        bakery: data.bakeryInfo,
        employmentStatus: data.employmentStatus,
        employeeRevenue: data.employeeRevenue
      }));
    } catch (error) {
      console.error('Error fetching bakery info:', error);
    } finally {
      setIsRefreshing(false);
    }
  }, [username, setGameState]);

  const fetchPriceHistory = useCallback(async (bakeryOwner) => {
    console.log(`Fetching price history for: ${bakeryOwner}`);
    try {
        const response = await fetch(`/api/bakery/priceHistory/${bakeryOwner}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Raw price history data:', data);

        // Sort data by timestamp in ascending order
        const sortedData = data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

        // Limit to the latest 50 data points
        const limitedData = sortedData.slice(-50).map(entry => ({
            timestamp: new Date(entry.timestamp).getTime(),
            price: parseFloat(entry.price.toFixed(4))
        }));
        console.log('Formatted price history data:', limitedData);
        setPriceHistory(limitedData);

        // Update latest prices
        if (limitedData.length > 0) {
            const latestPrice = limitedData[limitedData.length - 1].price;
            setLatestBuyPrice(latestPrice);
            setLatestSellPrice(latestPrice * 0.9); // Assuming sell price is 90% of buy price
        }
    } catch (error) {
        console.error('Error fetching price history:', error);
        setPriceHistory([]);
    }
  }, []);

  useEffect(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const handlePriceUpdate = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'priceUpdate' && data.bakeryOwner === selectedUser?.username) {
          setLatestBuyPrice(data.buyPrice);
          setLatestSellPrice(data.sellPrice);
          setPriceHistory(prevHistory => [
            ...prevHistory,
            { timestamp: Date.now(), price: data.buyPrice }
          ]);
        }
      };
  
      socket.addEventListener('message', handlePriceUpdate);
  
      return () => {
        socket.removeEventListener('message', handlePriceUpdate);
      };
    }
  }, [socket, selectedUser]);

  const handleSearch = useCallback(async () => {
    if (!searchTerm.trim()) {
      setSearchError('Please enter a username, bakery name, or employee name');
      return;
    }
  
    setIsSearching(true);
    setSearchError('');
  
    try {
      const response = await fetch(`/api/search-user?searchTerm=${encodeURIComponent(searchTerm)}`);      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
  
      if (!data || !data.bakeryName) {
        throw new Error('Invalid bakery data received');
      }
  
      setSelectedUser({
        username: data.ownerUsername,
        displayName: data.ownerDisplayName,
        title: data.searchedUserRole === 'employee' ? 'Employee' : 'Owner',
        searchedUsername: data.searchedUserRole === 'employee' ? searchTerm : data.ownerUsername,
        bakeryPriceInfo: {
          name: data.bakeryName,
          sharePrice: data.buyPrice,
          sellPrice: data.sellPrice,
          efficiency: data.efficiency,
          totalShares: data.totalShares,
          dailyProduction: data.dailyProduction,
          totalProduced: data.totalProduced,
          employeeCount: data.employeeCount
        },
        efficiency: data.efficiency,
        searchedUserRole: data.searchedUserRole
      });
  
      await fetchPriceHistory(data.ownerUsername);
  
    } catch (error) {
      console.error('Error in handleSearch:', error);
      setSearchError(error.message);
      setSelectedUser(null);
      setPriceHistory([]);
    } finally {
      setIsSearching(false);
    }
  }, [searchTerm, fetchPriceHistory]);

  const handleShareAmountChange = (newAmount) => {
    setShareAmount(Math.max(1, Math.min(newAmount, MAX_SHARE_AMOUNT)));
  };
  
  const handleShareAmountBlur = () => {
    let value = parseInt(shareAmountInput, 10);
    
    if (isNaN(value) || value < 1) {
      value = 1;
      setShareAmountInput('1');
      alert('Minimum share amount is 1.');
    } else if (value > MAX_SHARE_AMOUNT) {
      value = MAX_SHARE_AMOUNT;
      setShareAmountInput(MAX_SHARE_AMOUNT.toString());
      alert(`Maximum share amount is ${MAX_SHARE_AMOUNT}.`);
    }
    
    setShareAmount(value);
  };
  
  const handleTransaction = useCallback(async () => {
    if (isProcessing) return;
  
    setIsProcessing(true);
    try {
      const endpoint = isBuying ? '/api/shares/buy' : '/api/shares/sell';
      const timestamp = Date.now();
      const bodyData = isBuying
        ? { buyerUsername: username, subjectUsername: selectedUser.username, amount: shareAmount, timestamp }
        : { sellerUsername: username, subjectUsername: selectedUser.username, amount: shareAmount, timestamp };
  
      // Generate request signature
      const signature = await generateSignature(bodyData);
      bodyData.signature = signature;
  
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
  
      // Update game state
      setGameState(prevState => ({
        ...prevState,
        cookieBalance: result.newBalance,
      }));
  
      // Update portfolio locally
      setPortfolio(prevPortfolio => {
        const existingShareIndex = prevPortfolio.ownedShares.findIndex(share => share.username === selectedUser.username);
        let updatedOwnedShares = [...prevPortfolio.ownedShares];
  
        if (existingShareIndex !== -1) {
          // Update existing share count
          const newShareCount = result.ownedShares;
          if (newShareCount > 0) {
            updatedOwnedShares[existingShareIndex].shareCount = newShareCount;
          } else {
            // Remove the share entry if count is zero
            updatedOwnedShares.splice(existingShareIndex, 1);
          }
        } else if (isBuying && result.ownedShares > 0) {
          // Add new share entry only if buying and shareCount > 0
          updatedOwnedShares.push({ username: selectedUser.username, shareCount: result.ownedShares });
        }
  
        return {
          ...prevPortfolio,
          ownedShares: updatedOwnedShares,
        };
      });
  
      // Update price history locally
      setPriceHistory(prevHistory => [
        ...prevHistory,
        { timestamp: Date.now(), price: isBuying ? result.newBuyPrice : result.newSellPrice }
      ]);
  
      setLatestBuyPrice(result.newBuyPrice);
      setLatestSellPrice(result.newSellPrice);
  
      const transactionAmount = isBuying ? result.totalCost : result.totalRevenue;
      alert(`${isBuying ? 'Buy' : 'Sell'} successful! ${result.amount} share(s) ${isBuying ? 'bought' : 'sold'} for a total of ${transactionAmount.toFixed(4)} 🍪. New share price: ${result.newBuyPrice.toFixed(4)} 🍪`);
  
      // Send WebSocket message to update other clients
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({
          type: 'shareTransaction',
          data: {
            subjectUsername: selectedUser.username,
            newBuyPrice: result.newBuyPrice,
            newSellPrice: result.newSellPrice,
            totalShares: result.totalShares
          }
        }));
      }
  
      // Reset share amount after successful transaction
      setShareAmount(1);
  
    } catch (error) {
      console.error(`Error ${isBuying ? 'buying' : 'selling'} shares:`, error);
      alert(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  }, [isBuying, 
    username, 
    selectedUser,
     shareAmount, 
     setGameState, 
     setPortfolio, 
     setPriceHistory,
      setLatestBuyPrice, 
      setLatestSellPrice, 
      socket]);
  
  const handleBakeryNameChange = (e) => {
    setNewBakeryName(e.target.value);
  };

  const validateBakeryName = (name) => {
    if (!name || name.length < 3 || name.length > 30) {
      return 'Bakery name must be between 3 and 30 characters.';
    }
    return '';
  };
  

  const handleCreateBakery = useCallback(async () => {
    const error = validateBakeryName(newBakeryName);
    if (error) {
      setBakeryNameError(error);
      return;
    }
  
    setIsCreatingBakery(true);
    setBakeryNameError('');
  
    try {
      const response = await fetch('/api/bakery/create', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-Username': username
        },
        body: JSON.stringify({ bakeryName: newBakeryName }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Server response:', data);
  
      if (data.success) {
        setGameState(prevState => ({
          ...prevState,
          bakery: { 
            created: true, 
            name: data.bakery.name,
            dailyProduction: data.bakery.dailyProduction,
            totalProduced: data.bakery.totalProduced,
            efficiency: data.bakery.efficiency,
            employees: data.bakery.employees,
            isOwner: data.bakery.isOwner,
            totalShares: data.bakery.totalShares,
            sharePrice: data.bakery.sharePrice,
            upgrades: { standMixer: 0, oven: 0, display: 0 }, // Default upgrades
          },
          employmentStatus: 'owner',
        }));
        alert('Bakery created successfully!');
      } else {
        throw new Error(data.error || 'Failed to create bakery');
      }
    } catch (error) {
      console.error('Error creating bakery:', error);
      setBakeryNameError(`An error occurred: ${error.message}`);
    } finally {
      setIsCreatingBakery(false);
    }
  }, [newBakeryName, username, setGameState]);

  useEffect(() => {
    if (socket) {
      const handleMessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received WebSocket message:', data);
  
          switch (data.type) {
            case 'bakeryInfo':
              console.log('Received bakery info:', data.bakeryInfo);
              setBakeryInfo(data.bakeryInfo);
              setGameState(prevState => {
                const bakeryInfo = data.bakeryInfo || {};
                return {
                  ...prevState,
                  employmentStatus: data.employmentStatus || prevState.employmentStatus,
                  employeeRevenue: data.employeeRevenue || prevState.employeeRevenue,
                  unclaimedCookies: data.unclaimedCookies || prevState.unclaimedCookies,
                  bakery: {
                    ...prevState.bakery,
                    ...(bakeryInfo || {}),
                  },
                  consecutiveCheckIns: bakeryInfo.consecutiveCheckIns ?? prevState.consecutiveCheckIns,
                  lastCheckIn: bakeryInfo.lastCheckIn ? new Date(bakeryInfo.lastCheckIn) : prevState.lastCheckIn,
                  baseEfficiencyBoost: bakeryInfo.baseEfficiencyBoost ?? prevState.baseEfficiencyBoost,
                  consecutiveBonus: bakeryInfo.consecutiveBonus ?? prevState.consecutiveBonus,
                  totalEfficiencyBoost: bakeryInfo.totalEfficiencyBoost ?? prevState.totalEfficiencyBoost,
                };
              });
  
              if (data.bakeryInfo && data.bakeryInfo.lastCheckIn) {
                const nextCheckIn = new Date(data.bakeryInfo.lastCheckIn);
                nextCheckIn.setDate(nextCheckIn.getDate() + 1);
                setNextCheckIn(nextCheckIn);
                setCheckInAvailable(new Date() >= nextCheckIn);
              }
  
              setIsRefreshing(false);
              break;
  
            case 'updateCookieBalance':
              setGameState(prevState => ({
                ...prevState,
                cookieBalance: data.newBalance || prevState.cookieBalance,
              }));
              break;
  
            case 'shareTransaction':
              // Handle share transaction updates
              if (data.data && data.data.subjectUsername === selectedUser?.username) {
                setLatestBuyPrice(data.data.newBuyPrice);
                setLatestSellPrice(data.data.newSellPrice);
                // Update price history if needed
              }
              break;
  
            case 'error':
              console.error('Received error from server:', data.message);
              // Handle the error appropriately (e.g., show an error message to the user)
              break;
  
            default:
              console.log('Unhandled message type:', data.type);
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };
  
      socket.addEventListener('message', handleMessage);
  
      return () => {
        socket.removeEventListener('message', handleMessage);
      };
    }
  }, [socket, setGameState, setBakeryInfo, setNextCheckIn, setCheckInAvailable, setIsRefreshing, selectedUser, setLatestBuyPrice, setLatestSellPrice]);



  const handleKickEmployee = useCallback(async (employeeUsername) => {
    try {
      const response = await fetch('/api/bakery/kickEmployee', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ownerUsername: username, employeeUsername }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to kick employee');
      }
  
      const result = await response.json();
      setGameState(prevState => ({
        ...prevState,
        bakery: {
          ...prevState.bakery,
          employees: prevState.bakery.employees.filter(emp => emp.username !== employeeUsername),
        },
      }));
  
      alert(`Successfully kicked ${employeeUsername} from the bakery.`);
    } catch (error) {
      console.error('Error kicking employee:', error);
      alert(`Error: ${error.message}`);
    }
  }, [username, setGameState]);


  const handleCheckIn = useCallback(async () => {
    if (isProcessing) return;
  
    setIsProcessing(true);
    try {
      const response = await fetch('/api/dailyCheckIn', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username }),
      });
  
      const data = await response.json();
  
      if (response.ok && data.success) {
        setGameState((prevState) => ({
          ...prevState,
          cookieBalance: data.newCookieBalance,
          lastCheckIn: new Date(data.lastCheckIn),
          consecutiveCheckIns: data.consecutiveCheckIns,
          efficiency: data.efficiency,
        }));
        setCheckInAvailable(false);
        setNextCheckIn(new Date(data.nextCheckIn));
        setTimeRemaining(data.timeRemaining);
        alert(
          `Check-in successful! You earned ${data.bonus} cookies and a ${data.efficiencyBoost}% efficiency boost.`
        );
      } else {
        console.error('Check-in failed:', data.error);
        if (data.nextCheckIn) {
          setNextCheckIn(new Date(data.nextCheckIn));
          setTimeRemaining(data.timeRemaining);
        }
        alert(`Check-in failed: ${data.error}`);
      }
    } catch (error) {
      console.error('Check-in error:', error);
      alert(`Failed to check in. Please try again later.`);
    } finally {
      setIsProcessing(false);
    }
  }, [username, isProcessing, setGameState]);


  const handleLeaveBakery = useCallback(async () => {
    try {
      const response = await fetch('/api/bakery/leave', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-Username': username  // Add the username to the headers
        },
        // Remove the body as we're now sending the username in the headers
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to leave bakery');
      }
  
      const result = await response.json();
      console.log('Leave bakery result:', result);
      setGameState(prevState => ({
        ...prevState,
        bakery: { created: false, name: null, dailyProduction: 0, totalProduced: 0 },
        employmentStatus: 'unemployed',
        employedBy: null,
        employeeRevenue: 0,
      }));
  
      alert(result.message);
    } catch (error) {
      console.error('Error leaving bakery:', error);
      alert(`Error: ${error.message}`);
    }
  }, [username, setGameState]);
  
  const handleRefreshClick = useCallback(() => {
    setIsRefreshing(true);
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({
        type: 'getBakeryInfo',
        username: username
      }));
    }
  }, [socket, username]);

  const handleDailyCheckIn = useCallback(async () => {
    try {
      const response = await fetch('/api/dailyCheckIn', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: username }),
      });
      const data = await response.json();

      if (data.success) {
        setGameState(prevState => ({
          ...prevState,
          cookieBalance: data.cookieBalance,
          lastCheckIn: data.lastCheckIn,
          consecutiveCheckIns: data.consecutiveCheckIns,
        }));
        setLastCheckIn(new Date(data.lastCheckIn));
        setNextCheckIn(new Date(new Date(data.lastCheckIn).getTime() + 24 * 60 * 60 * 1000));
        setCheckInAvailable(false);
      } else {
        console.error('Check-in failed:', data.error);
        // Update next check-in time if provided
        if (data.nextCheckIn) {
          setNextCheckIn(new Date(data.nextCheckIn));
        }
      }
    } catch (error) {
      console.error('Error during check-in:', error);
    }
  }, [username, setGameState]);

  const getTimeUntilNextCheckIn = useCallback(() => {
    if (!nextCheckIn) return 'Unknown';
    const now = new Date();
    const timeDiff = nextCheckIn - now;
  
    if (timeDiff <= 0) return 'Available now';
  
    const hoursLeft = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutesLeft = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  
    return `${hoursLeft}h ${minutesLeft}m`;
  }, [nextCheckIn]);

  useEffect(() => {
    const fetchCheckInStatus = async () => {
      try {
        const response = await fetch(`/api/dailyCheckIn/status?username=${username}`);
        const data = await response.json();
  
        if (response.ok) {
          setCheckInAvailable(data.canCheckIn);
          setNextCheckIn(data.nextCheckIn ? new Date(data.nextCheckIn) : null);
          setTimeRemaining(data.timeRemaining);
        } else {
          console.error('Failed to fetch check-in status:', data.error);
        }
      } catch (error) {
        console.error('Error fetching check-in status:', error);
      }
    };
  
    fetchCheckInStatus();
  
    const interval = setInterval(fetchCheckInStatus, 60000); // Update every minute
  
    return () => clearInterval(interval);
  }, [username]);

useEffect(() => {
  const fetchCheckInStatus = async () => {
    try {
      const response = await fetch(`/api/dailyCheckIn/status?username=${username}`);
      const data = await response.json();

      if (response.ok) {
        setCheckInAvailable(data.canCheckIn);
        setNextCheckIn(data.nextCheckIn ? new Date(data.nextCheckIn) : null);
        setTimeRemaining(data.timeRemaining);
      } else {
        console.error('Failed to fetch check-in status:', data.error);
      }
    } catch (error) {
      console.error('Error fetching check-in status:', error);
    }
  };

  fetchCheckInStatus();

  const interval = setInterval(fetchCheckInStatus, 60 * 1000); // Every minute

  return () => clearInterval(interval);
}, [username]);

  // Add this useEffect to trigger refresh when the component mounts
  useEffect(() => {
    handleRefreshClick();
  }, [handleRefreshClick]);

  //#region BakeryTab
  const renderBakeryTab = useCallback(() => {
    console.log('Rendering bakery tab. Bakery:', gameState.bakery);
  
    if (!gameState.bakery || (gameState.employmentStatus !== 'employee' && gameState.employmentStatus !== 'owner')) {
      return (
        <div className="bg-black text-white p-6 rounded-lg">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <GiCookingPot className="mr-2" size={24} />
            No Bakery Information
          </h3>
          <p>You are not currently associated with any bakery.</p>
          <div className="mt-4">
            <input
              type="text"
              value={newBakeryName}
              onChange={handleBakeryNameChange}
              placeholder="Enter bakery name"
              className="w-full p-2 mb-2 bg-gray-800 text-white rounded"
            />
            {bakeryNameError && <p className="text-red-500 text-sm mb-2">{bakeryNameError}</p>}
            <button
              onClick={handleCreateBakery}
              disabled={isCreatingBakery}
              className={`w-full py-2 px-4 ${
                isCreatingBakery ? 'bg-gray-600 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
              } text-white rounded transition-colors duration-200`}
            >
              {isCreatingBakery ? 'Creating...' : 'Create Bakery'}
            </button>
          </div>
        </div>
      );
    }
  
    const isOwner = gameState.employmentStatus === 'owner';
    const bakery = gameState.bakery;
  
    if (!bakery) {
      return (
        <div className="bg-black text-white p-6 rounded-lg">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <FaSyncAlt className="mr-2" size={24} />
            Loading Bakery Information
          </h3>
          <p>Please wait while we fetch your bakery data...</p>
        </div>
      );
    }
  
    const { hours: hoursUntilNextCheckIn, minutes: minutesUntilNextCheckIn } = timeRemaining;
  
    const roundNumber = (num) => Math.round(num * 100) / 100;
    const dailyProduction = roundNumber(bakery.dailyProduction || 0);
    const employeeCount = bakery.employees?.length || 0;
    const ownerShare = isOwner
      ? (employeeCount > 0 ? roundNumber(dailyProduction * 0.6) : dailyProduction)
      : roundNumber(gameState.employeeRevenue || 0);
    const employeeShare = employeeCount > 0 ? roundNumber((dailyProduction * 0.4) / employeeCount) : 0;
  
    const marketCap = roundNumber(bakery.totalShares * bakery.sharePrice);
  
    return (
      <div className="bg-black text-white p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-bold flex items-center">
            <CakeIcon className="mr-2" size={24} />
            {bakery.name || 'Bakery'}
          </h3>
          <div className="flex items-center">
            <span className="mr-2 text-sm">{isOwner ? 'Owner' : 'Employee'}</span>
            <button
              onClick={fetchBakeryInfo}
              disabled={isRefreshing}
              className={`p-2 rounded-full bg-gray-800 text-white hover:bg-gray-700 transition-colors duration-200 ${
                isRefreshing ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isRefreshing ? (
                <RefreshCw size={20} className="animate-spin" />
              ) : (
                <RefreshCw size={20} />
              )}
            </button>
          </div>
        </div>
  
        <div className="mb-4">
          <p className="text-sm">
            Owned by: <span className="font-semibold">{bakery.owner}</span>
          </p>
        </div>
  
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="bg-gray-800 p-3 rounded">
            <h4 className="text-sm font-semibold mb-1">Daily Production</h4>
            <p className="text-lg">{dailyProduction} cookies</p>
            <p className="text-xs text-gray-400">
              Your share: {isOwner ? ownerShare : employeeShare} cookies
            </p>
          </div>
          <div className="bg-gray-800 p-3 rounded">
            <h4 className="text-sm font-semibold mb-1">Total Produced</h4>
            <p className="text-lg">{roundNumber(bakery.totalProduced || 0)} cookies</p>
          </div>
          <div className="bg-gray-800 p-3 rounded">
            <h4 className="text-sm font-semibold mb-1">Efficiency</h4>
            <p className="text-lg">{(bakery.efficiency * 100).toFixed(2)}%</p>
          </div>
          <div className="bg-gray-800 p-3 rounded">
            <h4 className="text-sm font-semibold mb-1">Employees</h4>
            <p className="text-lg">{employeeCount}</p>
          </div>
          <div className="bg-gray-800 p-3 rounded">
            <h4 className="text-sm font-semibold mb-1">Share Price</h4>
            <p className="text-lg">{roundNumber(bakery.sharePrice || 0)} cookies</p>
          </div>
          <div className="bg-gray-800 p-3 rounded">
            <h4 className="text-sm font-semibold mb-1">Market Cap</h4>
            <p className="text-lg">{marketCap} cookies</p>
          </div>
        </div>
  
        <div className="mb-4">
          <button
            onClick={handleCheckIn}
            disabled={!checkInAvailable || isProcessing}
            className={`w-full py-2 px-4 ${
              checkInAvailable && !isProcessing ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-600'
            } text-white rounded transition-colors duration-200`}
          >
            {isProcessing
              ? 'Processing...'
              : checkInAvailable
              ? 'Daily Check-In'
              : `Check-In Available in ${hoursUntilNextCheckIn}h ${minutesUntilNextCheckIn}m`}
          </button>
          {checkInAvailable && (
            <p className="text-sm text-gray-400 mt-1">
              Efficiency boost: +{((gameState.totalEfficiencyBoost || 0) * 100).toFixed(2)}% (Base:{' '}
              {((gameState.baseEfficiencyBoost || 0) * 100).toFixed(2)}% + Consecutive:{' '}
              {((gameState.consecutiveBonus || 0) * 100).toFixed(2)}%)
            </p>
          )}
  
          <button
            onClick={handleClaimCookies}
            disabled={gameState.unclaimedCookies <= 0 || isProcessing}
            className={`w-full mt-2 py-2 px-4 ${
              gameState.unclaimedCookies > 0 && !isProcessing ? 'bg-yellow-600 hover:bg-yellow-700' : 'bg-gray-600'
            } text-white rounded transition-colors duration-200`}
          >
            {isProcessing ? 'Claiming...' : `Claim Cookies (${gameState.unclaimedCookies.toFixed(2)} available)`}
          </button>
  
          <button
            onClick={handleLeaveBakery}
            className="w-full mt-2 py-2 px-4 bg-red-600 hover:bg-red-700 text-white rounded transition-colors duration-200"
          >
            {isOwner ? 'Transfer Ownership and Leave' : 'Leave Bakery'}
          </button>
        </div>
  
        {isOwner && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">Employees</h4>
            {bakery.employees && bakery.employees.length > 0 ? (
              <ul className="space-y-2">
                {bakery.employees.map((employee, index) => (
                  <li key={index} className="flex justify-between items-center">
                    <span>{employee.username}</span>
                    <button
                      onClick={() => handleKickEmployee(employee.username)}
                      className="py-1 px-2 bg-red-600 hover:bg-red-700 text-white rounded text-sm transition-colors duration-200"
                    >
                      Kick
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-400">No employees yet.</p>
            )}
          </div>
        )}
  
        <div className="mt-6 p-4 bg-gray-900 rounded-lg">
          <h4 className="text-lg font-bold mb-2">How Bakeries Work</h4>
          <ul className="list-disc list-inside space-y-2 text-sm">
            <li>Daily production is based on bakery efficiency and number of employees.</li>
            <li>Owners receive 60% of daily production (or 100% if no employees).</li>
            <li>Employees share 40% of daily production equally.</li>
            <li>Efficiency increases with daily check-ins and affects your contribution to production.</li>
            <li>You can invest in other players' bakeries to earn a share of their profits.</li>
            <li>Owners can transfer ownership when leaving if they have employees.</li>
          </ul>
        </div>
      </div>
    );
  }, [
    gameState,
    newBakeryName,
    bakeryNameError,
    isCreatingBakery,
    timeRemaining,
    checkInAvailable,
    isProcessing,
    isRefreshing,
    handleBakeryNameChange,
    handleCreateBakery,
    handleCheckIn,
    handleClaimCookies,
    handleLeaveBakery,
    fetchBakeryInfo,
    handleKickEmployee,
  ]);
//#region Investtab
const renderInvestTab = useCallback(() => {
  const ownedShareEntry = selectedUser
    ? portfolio.ownedShares.find(share => share.username === selectedUser.username)
    : null;
  const ownedShares = ownedShareEntry ? ownedShareEntry.shareCount : 0;

  return (
    <div className="bg-black text-white rounded-lg font-geist p-4">
      <h3 className="text-2xl font-bold mb-4 flex items-center">
        <Search className="mr-2" size={24} />
        Invest in Bakeries
      </h3>

      <div className="mb-4">
        <div className="flex mb-2">
          <button
            onClick={() => setSearchType('bakery')}
            className={`flex-1 py-2 px-4 rounded-l-lg ${
              searchType === 'bakery' ? 'bg-indigo-600 text-white' : 'bg-gray-800 text-gray-300'
            }`}
          >
            Search Bakery
          </button>
          <button
            onClick={() => setSearchType('user')}
            className={`flex-1 py-2 px-4 rounded-r-lg ${
              searchType === 'user' ? 'bg-indigo-600 text-white' : 'bg-gray-800 text-gray-300'
            }`}
          >
            Search User
          </button>
        </div>
        <div className="flex">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={`Search by ${searchType === 'bakery' ? 'bakery name' : 'username'}`}
            className="flex-grow p-2 rounded-l-lg bg-gray-800 text-white font-geist"
            disabled={isSearching || isProcessing}
          />
          <button
            onClick={handleSearch}
            disabled={isSearching || isProcessing}
            className={`bg-indigo-600 text-white px-4 py-2 rounded-r-lg hover:bg-indigo-700 transition-colors duration-200 font-geist ${
              isSearching ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isSearching ? <FaSpinner className="animate-spin" /> : <Search size={20} />}
          </button>
        </div>
        {searchError && <p className="text-red-500 mt-2 font-geist">{searchError}</p>}
      </div>

      <div className="mt-6">
        {isSearching ? (
          <p className="text-center font-geist">Searching...</p>
        ) : selectedUser ? (
          <div className="bg-gray-900 rounded-lg p-4">
            <div className="flex items-center mb-4">
              <div className="w-16 h-16 bg-indigo-500 rounded-full flex items-center justify-center text-white text-2xl font-bold mr-4 font-geist">
                {selectedUser.username ? selectedUser.username.charAt(0).toUpperCase() : '?'}
              </div>
              <div>
                <h3 className="text-xl font-bold font-geist">{selectedUser.username || 'Unknown User'}</h3>
                <p className="text-gray-400 font-geist">
                  {selectedUser.searchedUserRole === 'employee' 
                    ? `Employee at ${selectedUser.bakeryPriceInfo.name}`
                    : `Owner of ${selectedUser.bakeryPriceInfo.name}`
                  }
                </p>
              </div>
            </div>

            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-4 text-center font-geist">{selectedUser.bakeryPriceInfo.name}</h4>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div className="bg-black rounded-lg h-64">
                  {priceHistory.length > 0 ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart 
                        data={priceHistory}
                        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
                      >
                        <XAxis 
                          dataKey="timestamp" 
                          type="category"
                          tickFormatter={(unixTime) => new Date(unixTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          stroke="#718096"
                          tick={{ fill: '#718096', fontSize: 10 }}
                          axisLine={{ stroke: '#4A5568' }}
                          tickLine={{ stroke: '#4A5568' }}
                        />
                        <YAxis 
                          domain={['auto', 'auto']}
                          allowDataOverflow={true}
                          stroke="#718096"
                          tick={{ fill: '#718096', fontSize: 10 }}
                          axisLine={{ stroke: '#4A5568' }}
                          tickLine={{ stroke: '#4A5568' }}
                          tickFormatter={(value) => value.toFixed(2)}
                        />
                        <Tooltip
                          contentStyle={{ 
                            backgroundColor: 'rgba(26, 32, 44, 0.8)',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#E2E8F0',
                            fontFamily: 'Geist, sans-serif',
                            fontSize: '12px',
                            padding: '8px'
                          }}
                          labelFormatter={(label) => new Date(label).toLocaleString()}
                          formatter={(value) => [`${value.toFixed(4)} 🍪`, 'Price']}
                        />
                        <Line 
                          type="monotone" 
                          dataKey="price" 
                          stroke="#10B981" 
                          strokeWidth={2}
                          dot={false}
                          activeDot={{ r: 6, fill: '#10B981', stroke: '#0F9D58' }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  ) : (
                    <p className="text-center text-gray-400 font-geist">No price history available</p>
                  )}
                </div>
                <div className="grid grid-rows-2 gap-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-gray-800 p-4 rounded-lg">
                      <p className="text-sm text-gray-400 font-geist">Buy Price</p>
                      <p className="text-2xl font-bold text-green-400 font-geist">
                        {(latestBuyPrice || selectedUser.bakeryPriceInfo.sharePrice).toFixed(4)} 🍪
                      </p>
                    </div>
                    <div className="bg-gray-800 p-4 rounded-lg">
                      <p className="text-sm text-gray-400 font-geist">Sell Price</p>
                      <p className="text-2xl font-bold text-red-400 font-geist">
                        {(latestSellPrice || selectedUser.bakeryPriceInfo.sellPrice).toFixed(4)} 🍪
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-gray-800 p-4 rounded-lg">
                      <p className="text-sm text-gray-400 font-geist">Bakery Efficiency</p>
                      <p className="text-2xl font-bold text-blue-400 font-geist">
                        {(selectedUser.bakeryPriceInfo.efficiency * 100).toFixed(2)}%
                      </p>
                    </div>
                    <div className="bg-gray-800 p-4 rounded-lg">
                      <p className="text-sm text-gray-400 font-geist">Total Shares</p>
                      <p className="text-2xl font-bold text-purple-400 font-geist">
                        {selectedUser.bakeryPriceInfo.totalShares}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-6">
                <p className="text-sm text-gray-300 mb-2 font-geist">Your cookie balance: {cookieBalance.toFixed(2)} 🍪</p>
                <p className="text-sm text-gray-300 mb-4 font-geist">Your owned shares: {ownedShares}</p>
                
                <div className="flex items-center mb-4">
                  <button
                    onClick={() => handleShareAmountChange(Math.max(1, shareAmount - 1))}
                    className="px-3 py-1 bg-gray-700 text-white rounded-l"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={shareAmount}
                    onChange={(e) => handleShareAmountChange(e.target.value)}
                    onBlur={() => handleShareAmountChange(shareAmount)} // Ensure valid value on blur
                    min="1"
                    max={MAX_SHARE_AMOUNT}
                    className="w-20 p-2 text-center bg-gray-800 text-white font-geist"
                    style={{ caretColor: 'white' }}
                  />
                  <button
                    onClick={() => handleShareAmountChange(Math.min(MAX_SHARE_AMOUNT, shareAmount + 1))}
                    className="px-3 py-1 bg-gray-700 text-white rounded-r"
                  >
                    +
                  </button>
                </div>
                
                <p className="text-sm text-gray-300 font-geist mb-4">
                  Total {isBuying ? 'cost' : 'revenue'}: {totalCost.toFixed(4)} 🍪
                </p>
                
                <div className="flex justify-between mb-4">
                  <button
                    onClick={() => setIsBuying(true)}
                    className={`flex-1 py-3 px-4 rounded-l-full transition-colors duration-200 font-geist ${
                      isBuying ? 'bg-green-600 text-white' : 'bg-gray-800 text-gray-300 hover:bg-green-700'
                    }`}
                  >
                    Buy
                  </button>
                  <button
                    onClick={() => setIsBuying(false)}
                    className={`flex-1 py-3 px-4 rounded-r-full transition-colors duration-200 font-geist ${
                      !isBuying ? 'bg-red-600 text-white' : 'bg-gray-800 text-gray-300 hover:bg-red-700'
                    }`}
                  >
                    Sell
                  </button>
                </div>
              </div>

              <button
                onClick={handleTransaction}
                disabled={isProcessing}
                className={`w-full py-3 px-4 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition-colors duration-200 font-geist ${
                  isProcessing ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isProcessing ? 'Processing...' : isBuying ? 'Buy Shares' : 'Sell Shares'}
              </button>
            </div>
          </div>
        ) : (
          <p className="text-sm text-gray-400 font-geist">Search for a bakery to invest in.</p>
        )}
      </div>
    </div>
  );
}, [
  searchTerm,
  isSearching,
  searchError,
  selectedUser,
  portfolio.ownedShares,
  cookieBalance,
  isBuying,
  handleSearch,
  handleTransaction,
  priceHistory,
  setSearchTerm,
  setIsBuying,
  latestBuyPrice,
  latestSellPrice,
  shareAmount,
  totalCost,
  setShareAmount,
  isProcessing,
  handleShareAmountChange,
  MAX_SHARE_AMOUNT,
  searchType,
  setSearchType
]);

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA336A'];

  //#region Portfolio
  const renderPortfolioTab = useCallback(() => {
    const totalValue = calculateTotalValue(portfolio.ownedShares || []);

    return (
      <div className="bg-black text-white p-4 rounded-lg font-geist">
        <h3 className="text-2xl font-bold mb-4 flex items-center">
          <FaBriefcase className="mr-2" size={24} />
          Your Portfolio
        </h3>

        {/* Portfolio Summary */}
        <div className="bg-gray-800 p-4 rounded-lg mb-6">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm">Cookie Balance</span>
            <span className="text-lg font-bold">
              {gameState.cookieBalance.toFixed(2)} 🍪
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm">Total Investments</span>
            <span className="text-lg font-bold">
              {portfolio.ownedShares?.length || 0}
            </span>
          </div>
        </div>

        {/* Portfolio Table */}
        <div className="bg-gray-800 p-4 rounded-lg mb-6 overflow-x-auto">
          <h4 className="text-lg font-semibold mb-4 flex items-center">
            <FaCoins className="mr-2" size={20} />
            Your Investments
          </h4>
          {portfolio.ownedShares && portfolio.ownedShares.length > 0 ? (
            <table className="w-full text-sm">
              <thead>
                <tr className="bg-gray-700">
                  <th className="py-2 px-3 text-left">Bakery</th>
                  <th className="py-2 px-3 text-right">Shares Owned</th>
                  <th className="py-2 px-3 text-right">Share Price</th>
                  <th className="py-2 px-3 text-right">Investment Value</th>
                </tr>
              </thead>
              <tbody>
                {portfolio.ownedShares.map((share, index) => {
                  const investmentValue = share.shareCount * share.sharePrice;
                  return (
                    <tr
                      key={share.username}
                      className={index !== portfolio.ownedShares.length - 1 ? "border-b border-gray-700" : ""}
                    >
                      <td className="py-2 px-3">{share.bakeryName || share.username}</td>
                      <td className="py-2 px-3 text-right">{share.shareCount}</td>
                      <td className="py-2 px-3 text-right">{share.sharePrice.toFixed(4)} 🍪</td>
                      <td className="py-2 px-3 text-right">{investmentValue.toFixed(2)} 🍪</td>
                    </tr>
                  );
                })}
                {/* Total Row */}
                <tr className="bg-gray-700">
                  <td className="py-2 px-3 font-bold">Total</td>
                  <td className="py-2 px-3 text-right font-bold">
                    {portfolio.ownedShares.reduce((sum, share) => sum + share.shareCount, 0)}
                  </td>
                  <td className="py-2 px-3 text-right"></td>
                  <td className="py-2 px-3 text-right font-bold">{totalValue.toFixed(2)} 🍪</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <p className="text-center text-gray-400">You haven't invested in any bakeries yet.</p>
          )}
        </div>

        {/* Refresh Portfolio Button */}
        <div className="flex justify-center">
          <button
            onClick={fetchPortfolio}
            disabled={isLoadingPortfolio}
            className={`flex items-center space-x-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded transition-colors duration-200 ${
              isLoadingPortfolio ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <FaSyncAlt />
            <span>Refresh Portfolio</span>
          </button>
        </div>
      </div>
    );
  }, [
    gameState.cookieBalance,
    portfolio,
    isLoadingPortfolio,
    fetchPortfolio,
    calculateTotalValue,
  ]);
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className={`fixed inset-0 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'} overflow-y-auto z-50`}
    >
      <div className="w-full max-w-7xl mx-auto pb-20">
        <div className="flex items-center justify-between mb-4">
          <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            Bakery & Investments
          </h2>
        </div>

        <div className="mb-6">
          <div className="flex space-x-2 mb-4">
            {['bakery', 'invest', 'portfolio'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`flex-1 py-2 px-4 rounded-full ${
                  activeTab === tab
                    ? isDarkMode
                      ? 'bg-indigo-600 text-white'
                      : 'bg-indigo-500 text-white'
                    : isDarkMode
                    ? 'bg-gray-700 text-gray-300'
                    : 'bg-gray-200 text-gray-700'
                } transition-colors duration-200`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>

          <div className="w-full">
            {activeTab === 'bakery' && renderBakeryTab()}
            {activeTab === 'invest' && renderInvestTab()}
            {activeTab === 'portfolio' && renderPortfolioTab()}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
//#region InvestmentEND
const useNightTimeDetection = () => {
  const [isNightTime, setIsNightTime] = useState(false);

  useEffect(() => {
    const checkNightTime = () => {
      const hour = new Date().getHours();
      setIsNightTime(hour >= 22 || hour < 6);
    };

    checkNightTime();
    const interval = setInterval(checkNightTime, 60000); // Check every minute
    return () => clearInterval(interval);
  }, []);

  return isNightTime;
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('ErrorBoundary caught an error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function RewardsScreen({ cookieCount, checkInAvailable, onCheckIn, consecutiveCheckIns, isDarkMode, onClose }) {
  const [activeTab, setActiveTab] = useState('investment');

  return (
    <div className={`fixed inset-0 bg-gradient-to-b ${isDarkMode ? 'from-gray-900 to-gray-800' : 'from-blue-100 to-purple-100'} flex flex-col items-center p-4 z-50 overflow-y-auto`}>
      <div className="w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-indigo-700'}`}>Rewards</h2>
          <button onClick={onClose} className={`${isDarkMode ? 'text-gray-300 hover:text-gray-100' : 'text-gray-500 hover:text-gray-700'}`}>
            <X size={24} />
          </button>
        </div>

        <div className="flex mb-4">
          <button
            onClick={() => setActiveTab('investment')}
            className={`flex-1 py-2 ${activeTab === 'investment' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Investment
          </button>
          <button
            onClick={() => setActiveTab('airdrop')}
            className={`flex-1 py-2 ${activeTab === 'airdrop' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Airdrop
          </button>
        </div>

        {activeTab === 'investment' && (
<InvestmentScreen 
  onClose={() => setShowInvestment(false)}
  isDarkMode={isDarkMode}
  gameState={gameState}
  username={username}
  cookieBalance={gameState.cookieBalance}
  workplace={gameState.workplace}
/>
        )}

        {activeTab === 'airdrop' && (
          <AirdropScreen />
        )}
      </div>
    </div>
  );
}
//#region Airdrop

const AirdropScreen = ({ onClose, isDarkMode }) => {
  // Placeholder for conditional visibility
  const showTasks = false;
  const showReferrals = false;

  const textStyle = {
    color: 'black',
    textShadow: `
      -1px -1px 0 #fff,  
       1px -1px 0 #fff,
      -1px  1px 0 #fff,
       1px  1px 0 #fff,
      -2px -2px 0 #fff,  
       2px -2px 0 #fff,
      -2px  2px 0 #fff,
       2px  2px 0 #fff`,
    WebkitTextStroke: '1px white',
    textStroke: '2px white',
  };

  return (
    <button 
      className="fixed inset-0 w-full h-full flex flex-col justify-center items-center z-50"
      onClick={onClose}
      style={{
        backgroundImage: `url(${loungeBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="w-full max-w-md p-4 text-center mb-12">
        <h3 className="text-4xl font-bold mb-4" style={textStyle}>Coming Soon!</h3>
        <p className="text-2xl mb-8" style={textStyle}>Exciting features are on their way. Stay tuned!</p>
        
        {showTasks && (
          <div className="bg-white bg-opacity-60 p-4 rounded-lg mb-4">
            <h4 className="text-2xl font-bold mb-2" style={textStyle}>Daily Tasks</h4>
            <p style={textStyle}>Complete tasks to earn rewards!</p>
          </div>
        )}
        
        {showReferrals && (
          <div className="bg-white bg-opacity-60 p-4 rounded-lg">
            <h4 className="text-2xl font-bold mb-2" style={textStyle}>Invite Friends</h4>
            <p style={textStyle}>Earn bonuses by inviting your friends!</p>
          </div>
        )}
      </div>
    </button>
  );
};
//#region ReferralScreen
const ReferralScreen = ({ onClose, isDarkMode }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.3 }}
          className={`fixed inset-0 z-50 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'} overflow-y-auto`}
        >
          <div className="container mx-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className={`text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Friend Referrals</h2>
              <button
                onClick={handleClose}
                className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' : 'bg-gray-200 text-gray-600 hover:bg-gray-300'}`}
              >
                <X size={24} />
              </button>
            </div>

            <div className={`grid grid-cols-1 md:grid-cols-3 gap-6 mb-8`}>
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.1 }}
                className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
              >
                <Users size={40} className={`mb-4 ${isDarkMode ? 'text-indigo-400' : 'text-indigo-600'}`} />
                <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Total Referrals</h3>
                <p className={`text-3xl font-bold ${isDarkMode ? 'text-indigo-400' : 'text-indigo-600'}`}>0</p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.2 }}
                className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
              >
                <Gift size={40} className={`mb-4 ${isDarkMode ? 'text-green-400' : 'text-green-600'}`} />
                <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Rewards Earned</h3>
                <p className={`text-3xl font-bold ${isDarkMode ? 'text-green-400' : 'text-green-600'}`}>0</p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.3 }}
                className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
              >
                <Trophy size={40} className={`mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-yellow-600'}`} />
                <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Referral Rank</h3>
                <p className={`text-3xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-yellow-600'}`}>-</p>
              </motion.div>
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} mb-8`}
            >
              <h3 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Your Referral Link</h3>
              <div className="flex items-center">
                <input
                  type="text"
                  readOnly
                  value="https://example.com/ref/your_unique_code"
                  className={`flex-grow p-2 rounded-l-lg ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
                />
                <button className={`px-4 py-2 rounded-r-lg ${isDarkMode ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-indigo-500 hover:bg-indigo-600'} text-white`}>
                  Copy
                </button>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
            >
              <h3 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Referral Leaderboard</h3>
              <p className={`text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>No referrals yet. Start inviting friends to see the leaderboard!</p>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
//#region HireFriendsScreen
function HireFriendsScreen({ 
  onClose, 
  isDarkMode, 
  gameState,
  setGameState,
  username,
  referralCode,
  isReferralCodeLoading,
  BOT_USERNAME,
  cookieBalance,
  onTwitterBonusClaimed,
  WebApp
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [error, setError] = useState(null);
  const [utils, setUtils] = useState(null);
  const [twitterBonusClaimed, setTwitterBonusClaimed] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleConnectTwitter = useCallback(() => {
    const apiUrl = process.env.REACT_APP_API_URL || 'https://momomusume.com';
    const twitterAuthUrl = `${apiUrl}/auth/twitter?username=${encodeURIComponent(username)}`;
  
    console.log('Connecting to Twitter with URL:', twitterAuthUrl);
  
    window.location.href = twitterAuthUrl;
  }, [username]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authStatus = urlParams.get('auth_status');
    const bonusAmount = urlParams.get('bonus');
    const message = urlParams.get('message');

    if (authStatus === 'success') {
      setTwitterBonusClaimed(true);
      if (bonusAmount) {
        alert(`Congratulations! You've been awarded ${bonusAmount} cookies for following us on Twitter!`);
        onTwitterBonusClaimed(gameState.cookieBalance + parseInt(bonusAmount, 10));
      }
    } else if (authStatus === 'already_claimed') {
      setError('You have already claimed the Twitter follower bonus.');
      alert('You have already claimed the Twitter follower bonus.');
    } else if (authStatus === 'error' && message) {
      setError(message);
      alert(`Error: ${message}`);
    }

    window.history.replaceState({}, document.title, window.location.pathname);
  }, [gameState.cookieBalance, onTwitterBonusClaimed]);

  useEffect(() => {
    const initializeUtils = async () => {
      const initializedUtils = await initUtils();
      setUtils(initializedUtils);
    };
    initializeUtils();
  }, []);

  const handleShareWithFriends = () => {
    if (!referralCode) {
      console.error('Referral code not available');
      alert('Referral code not available. Please try again later.');
      return;
    }

    const referralLink = `https://t.me/${BOT_USERNAME}?start=${referralCode}`;
    const shareText = `Hey! Check out Momowars! Join me and get a percentage when people invest or divest from my company🎁! Just use my referral link to get started!

🍪 Cherish your character with taps, patting and feeding, build bakeries and become a tycoon!
🏆 Let's compete with other bakeries and climb the leaderboard together

Let's bake!`;

    const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(shareText)}`;

    if (utils) {
      try {
        utils.openTelegramLink(shareUrl);
        console.log('Opening Telegram share URL');
      } catch (error) {
        console.error('Error opening Telegram share URL:', error);
        // Handle fallback if needed
      }
    } else {
      console.error("Telegram utils not available");
      // Handle fallback if needed
    }
  };

  const handleCopyReferralLink = () => {
    if (!referralCode) {
      console.error('Referral code not available');
      alert('Referral code not available. Please try again later.');
      return;
    }
  
    const referralLink = `https://t.me/${BOT_USERNAME}?start=${referralCode}`;
    navigator.clipboard.writeText(referralLink).then(() => {
      setCopiedToClipboard(true);
      setTimeout(() => setCopiedToClipboard(false), 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
      setError('Failed to copy referral link');
    });
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.3 }}
          className={`fixed inset-0 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'} bg-opacity-90 flex items-center justify-center p-4 z-50`}
        >
          <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-full max-w-md`}>
            <h2 className="text-2xl font-bold mb-4">Invite Friends</h2>
            <p className="mb-4">Share your referral link with friends and earn bonus cookies!</p>
            
            <div className="mb-4">
              <button 
                onClick={handleShareWithFriends}
                className={`w-full ${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white py-2 px-4 rounded transition-colors duration-200`}
              >
                Share with Friends
              </button>
            </div>
            
            <div className="mb-4">
              <p className="font-semibold mb-2">Your Referral Code:</p>
              <div className="flex">
                <input 
                  type="text"
                  readOnly
                  value={referralCode || 'Loading...'}
                  className={`flex-grow p-2 border rounded-l ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-700'}`}
                />
                <button 
                  onClick={handleCopyReferralLink}
                  className={`${isDarkMode ? 'bg-gray-600 hover:bg-gray-500' : 'bg-gray-200 hover:bg-gray-300'} px-4 rounded-r transition-colors duration-200`}
                >
                  Copy
                </button>
              </div>
              {copiedToClipboard && <p className="text-green-500 text-sm mt-1">Copied to clipboard!</p>}
            </div>
  
            <div className="mb-4">
              <button 
                className={`w-full bg-gray-500 text-gray-300 py-2 px-4 rounded cursor-not-allowed opacity-50`}
                disabled={true}
              >
                Twitter Claim Coming Soon
              </button>
              <p className="text-yellow-500 text-sm mt-1">Twitter connection is temporarily unavailable.</p>
            </div>
            
            <button 
              onClick={onClose}
              className={`w-full ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-300 hover:bg-gray-400'} text-gray-800 py-2 px-4 rounded transition-colors duration-200`}
            >
              Close
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
//#endregion HireFriendsScreen
//#region APP
function App() {

  const [socket, setSocket] = useState(null);
  
  useEffect(() => {
    const newSocket = new WebSocket('wss://momomusume.com/ws');

    newSocket.onopen = () => {
      console.log('WebSocket connection established');
      setSocket(newSocket);
    };

    newSocket.onclose = () => {
      console.log('WebSocket connection closed. Attempting to reconnect...');
      setTimeout(() => {
        setSocket(null);
      }, 1000);
    };

    return () => {
      if (newSocket) {
        newSocket.close();
      }
    };
  }, []);



  const [gameState, setGameState] = useState({
    gameStage: 'character',
    selectedCharacter: 'peach',
    taps: 0,
    exp: 0,
    cookieBalance: 0,
    relationshipStage: 'Stranger',
    tapLimit: 1000,
    lastCheckIn: null,
    consecutiveCheckIns: 0,
    overallMood: 'neutral',
    hearts: 25,
    comfort: 50,
    fullness: 50,
    mood: 'NEUTRAL',
    bakery: {
      created: false,
      name: null,
      dailyProduction: 0,
      totalProduced: 0,
      efficiency: 0,
      userEfficiency: 0,
      employees: [],
    },  
    employeeRevenue: 0,
    tutorialShown: false, // Add this new property
    ownedShares: [],
  });

  const [showHelp, setShowHelp] = useState(false);
  const [activeTab, setActiveTab] = useState('main');
  const [maxTapLimit] = useState(1000);
  const [checkInAvailable, setCheckInAvailable] = useState(false);
  const [error, setError] = useState(null);
  const [characterStageReady, setCharacterStageReady] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showAirdrop, setShowAirdrop] = useState(false);
  const [activeScreen, setActiveScreen] = useState('main');
  const [showIndicators, setShowIndicators] = useState(false);
  const [cookiesPerHour, setCookiesPerHour] = useState(0);
  const [userId, setUserId] = useState(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const [speechText, setSpeechText] = useState("Hello world");
  const [speechMessages, setSpeechMessages] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [referralCode, setReferralCode] = useState('');
  const [isReferralCodeLoading, setIsReferralCodeLoading] = useState(true);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [workplace, setWorkplace] = useState(null);
  const [lastCheckIn, setLastCheckIn] = useState(null);
  const [nextCheckIn, setNextCheckIn] = useState(null);
  const [twitterFollowerBonusClaimed, setTwitterFollowerBonusClaimed] = useState(false);

  const BOT_USERNAME = 'HermoineHogwartsbot';
  const [username, setUsername] = useState('');
  const MINI_APP_URL = process.env.MINI_APP_URL;

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      // Disable vertical swipes when the app initializes
      window.Telegram.WebApp.disableVerticalSwipes();
      
      // Inform Telegram that the Mini App is ready
      window.Telegram.WebApp.ready();
    }
  }, []);

  const [showStatus, setShowStatus] = useState(false);
  const [showInvestment, setShowInvestment] = useState(false);
  const [showHireFriends, setShowHireFriends] = useState(false);
  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);

  const showBack = useCallback(() => {
    setShowBackButton(true);
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.show();
    }
  }, []);


  const hideBack = useCallback(() => {
    setShowBackButton(false);
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.hide();
    }
  }, []);

  const handleOpenStatus = useCallback(() => {
    setShowStatus(true);
    setLeftMenuOpen(false);
    showBack();
  }, [showBack]);

  
  const handleCloseStatus = useCallback(() => {
    setShowStatus(false);
    setLeftMenuOpen(false);
    hideBack();
  }, [hideBack]);

  const handleOpenInvestment = useCallback(() => {
    setShowInvestment(true);
    setRightMenuOpen(false);
    showBack();
  }, [showBack]);


  const handleCloseInvestment = useCallback(() => {
    setShowInvestment(false);
    setRightMenuOpen(false);
    hideBack();
  }, [hideBack]);

  const openHireFriendsScreen = useCallback(() => {
    setShowHireFriends(true);
    setLeftMenuOpen(false);
    setRightMenuOpen(false);
    showBack();
  }, [showBack]);

  const closeHireFriendsScreen = useCallback(() => {
    setShowHireFriends(false);
    hideBack();
  }, [hideBack]);

  const openLeftMenu = useCallback(() => {
    setLeftMenuOpen(true);
    setRightMenuOpen(false);
    setShowStatus(false);
    setShowInvestment(false);
    setShowHireFriends(false);
    showBack();
  }, [showBack]);

  const openRightMenu = useCallback(() => {
    setRightMenuOpen(true);
    setLeftMenuOpen(false);
    setShowStatus(false);
    setShowInvestment(false);
    setShowHireFriends(false);
    showBack();
  }, [showBack]);

  
  const closeMenus = useCallback(() => {
    setLeftMenuOpen(false);
    setRightMenuOpen(false);
    setShowStatus(false);
    setShowInvestment(false);
    setShowHireFriends(false);
    hideBack();
  }, [hideBack]);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
  
      const handleBackButton = () => {
        if (showHireFriends) {
          closeHireFriendsScreen();
        } else if (showInvestment) {
          handleCloseInvestment();
        } else if (showStatus) {
          handleCloseStatus();
        } else {
          closeMenus();
        }
      };
  
      window.Telegram.WebApp.BackButton.onClick(handleBackButton);
  
      return () => {
        window.Telegram.WebApp.BackButton.offClick(handleBackButton);
      };
    }
  }, [closeHireFriendsScreen, handleCloseInvestment, handleCloseStatus, closeMenus, showHireFriends, showInvestment, showStatus]);







  const getWebSocketUrl = () => {
    return 'wss://momomusume.com';
  };

  const lastDistributionTimeRef = useRef(gameState.bakery.lastDistributionTime);

  useEffect(() => {
    lastDistributionTimeRef.current = gameState.bakery.lastDistributionTime;
  }, [gameState.bakery.lastDistributionTime]);

  const setGameStateAndSave = useCallback(
    (newState) => {
      setGameState((prevState) => {
        const updatedState =
          typeof newState === 'function' ? newState(prevState) : newState;
        const roundedState = {
          ...prevState,
          ...updatedState,
          hearts: Math.round(updatedState.hearts),
          fullness: Math.round(updatedState.fullness),
          comfort: Math.round(updatedState.comfort),
          cookieBalance: Math.round(updatedState.cookieBalance),
          tutorialShown: updatedState.tutorialShown !== undefined
            ? updatedState.tutorialShown
            : prevState.tutorialShown,
          ownedShares: updatedState.ownedShares || prevState.ownedShares || {},
          efficiency: updatedState.efficiency !== undefined ? updatedState.efficiency : prevState.efficiency,
          bakery: {
            ...prevState.bakery,
            ...(updatedState.bakery || {}),
          },
        };
  
        const stateToSave = {
          ...roundedState,
          tutorialShown: roundedState.tutorialShown,
        };
  
        if (socket && socket.readyState === WebSocket.OPEN) {
          socket.send(
            JSON.stringify({
              type: 'updateGameState',
              username: username,
              gameState: stateToSave,
            })
          );
        } else {
          fetch('/api/updateUserData', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              username: username,
              gameState: stateToSave,
              ownedShares: stateToSave.ownedShares,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success && data.user.referralCode) {
                console.log(
                  'Received referral code from server:',
                  data.user.referralCode
                );
                setReferralCode(data.user.referralCode);
              }
            })
            .catch((error) =>
              console.error('Error saving game state:', error)
            );
        }
  
        return roundedState;
      });
    },
    [username, socket, setReferralCode]
  );
  


  function fetchUsernameAndReferralCode() {
    if (window.Telegram?.WebApp) {
      const initData = window.Telegram.WebApp.initData;
      if (initData) {
        try {
          const parsedInitData = JSON.parse(decodeURIComponent(initData));
          const username = parsedInitData.user?.username || parsedInitData.user?.first_name || 'Anonymous';
  
          fetch(`${MINI_APP_URL}/api/referral/getOrGenerate`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.referralCode) {
                setReferralCode(data.referralCode);
                setIsReferralCodeLoading(false);
              } else {
                throw new Error('Failed to get or generate referral code');
              }
            })
            .catch((error) => {
              console.error('Error fetching referral code:', error);
              setIsReferralCodeLoading(false);
              setError('Failed to get referral code');
            });
  
          return username;
        } catch (error) {
          console.error('Error parsing initData:', error);
        }
      }
    }
    return 'Anonymous';
  }

  useEffect(() => {
    const username = fetchUsernameAndReferralCode();
    setUsername(username);
  }, []);

  const fetchReferralCode = useCallback(async () => {
    console.log('fetchReferralCode function called');
    console.log('Current username:', username);
  
    if (!username || username === 'Anonymous') {
      console.error('Invalid username for fetchReferralCode');
      setError('Unable to fetch referral code: No valid username');
      setIsReferralCodeLoading(false);
      return;
    }
  
    try {
      setIsReferralCodeLoading(true);
      const url = `${MINI_APP_URL}/api/referral/getData`;
      console.log('Sending GET request to:', url);
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Username: username,
        },
      });
  
      console.log('Received response with status:', response.status);
      const data = await response.json();
      console.log('Response data:', data);
  
      if (data.referralCode) {
        console.log('Setting referral code:', data.referralCode);
        setReferralCode(data.referralCode);
      } else {
        console.error('No referral code in response data');
        setError('Failed to retrieve referral code');
      }
    } catch (error) {
      console.error('Error in fetchReferralCode:', error);
      setError(`Failed to get referral code: ${error.message}`);
    } finally {
      setIsReferralCodeLoading(false);
    }
  }, [username, MINI_APP_URL, setError, setReferralCode]);



  useEffect(() => {
    if (username) {
      fetchReferralCode();
    }
  }, [username, fetchReferralCode]);

  useEffect(() => {
    if (rightMenuOpen) {
      fetchReferralCode();
    }
  }, [rightMenuOpen, fetchReferralCode]);

  useEffect(() => {
    const loadData = async () => {
      if (username) {
        console.log('Loading data for user:', username);
        try {
          const response = await fetch(`/api/getUserData?username=${username}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const userData = await response.json();
    
          if (userData) {
            console.log('Loaded user data:', userData);
    
            const currentTime = Date.now();
            const lastUpdateTime = userData.gameState.lastUpdateTime || currentTime;
            const elapsedHours = (currentTime - lastUpdateTime) / (1000 * 60 * 60);
    
            const hearts = Math.max(0, Math.round((userData.gameState.hearts || 25) - elapsedHours * 8));
            const fullness = Math.max(0, Math.round((userData.gameState.fullness || 50) - elapsedHours * 3));
            const comfort = Math.max(0, Math.round((userData.gameState.comfort || 50) - elapsedHours * 5));
    
            const lastCheckInDate = userData.gameState.lastCheckIn ? new Date(userData.gameState.lastCheckIn) : null;
            const nextCheckInDate = userData.gameState.nextCheckIn ? new Date(userData.gameState.nextCheckIn) : new Date(currentTime + 24 * 60 * 60 * 1000);
    
            const updatedGameState = {
              ...userData.gameState,
              hearts,
              fullness,
              comfort,
              lastUpdateTime: currentTime,
              lastCheckIn: lastCheckInDate,
              cookieBalance: userData.gameState.cookieBalance || 0,
              ownedShares: Array.isArray(userData.ownedShares) ? userData.ownedShares : [],
              bakery: userData.bakery ? {
                ...userData.bakery,
                created: true,
                name: userData.bakery.name || null,
                dailyProduction: userData.bakery.dailyProduction || 0,
                totalProduced: userData.bakery.totalProduced || 0,
                efficiency: userData.bakery.efficiency || 0,
                employees: userData.bakery.employees || []
              } : {
                created: false,
                name: null,
                dailyProduction: 0,
                totalProduced: 0,
                efficiency: 0,
                employees: []
              },
              tutorialShown: userData.gameState.tutorialShown || false,
              unclaimedCookies: userData.gameState.unclaimedCookies || 0,
              userEfficiency: userData.userEfficiency || 1,
              twitterFollowerBonusClaimed: userData.twitterFollowerBonusClaimed || false, // Add this line
            };
    
            // Remove the tutorialJustCompleted flag if it exists
            if (updatedGameState.tutorialJustCompleted) {
              delete updatedGameState.tutorialJustCompleted;
            }
    
            setGameState((prevState) => ({
              ...prevState,
              ...updatedGameState,
            }));
    
            setLastCheckIn(lastCheckInDate);
            setNextCheckIn(nextCheckInDate);
            setCheckInAvailable(currentTime >= nextCheckInDate.getTime());
    
            setReferralCode(userData.referralCode || '');
            setWorkplace(userData.workplace || null);
    
            setEmploymentStatus(userData.employmentStatus || 'unemployed');
            setEmployeeRevenue(userData.employeeRevenue || 0);
    
            setGameStateAndSave(updatedGameState);
          } else {
            console.log('No saved game state found for user:', username);
            setGameState((prevState) => ({
              ...prevState,
              hearts: 25,
              comfort: 50,
              fullness: 50,
              mood: 'NEUTRAL',
              exp: 0,
              taps: 0,
              cookieBalance: 0,
              lastFed: Date.now(),
              lastUpdateTime: Date.now(),
              efficiency: 0,
              consecutiveCheckIns: 0,
              tutorialShown: false,
              ownedShares: {},
              bakery: {
                created: false,
                name: null,
                dailyProduction: 0,
                totalProduced: 0,
                efficiency: 0,
                employees: []
              },
              unclaimedCookies: 0,
              userEfficiency: 1,
              twitterFollowerBonusClaimed: false, // Add this line
            }));
          }
        } catch (error) {
          console.error('Error loading game state:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    loadData();
  }, [username, setGameStateAndSave]);
  const isNightTime = useNightTimeDetection();

  const checkAvailability = useCallback(() => {
    const now = Date.now();
    const hoursSinceLastCheckIn = gameState.lastCheckIn ? (now - gameState.lastCheckIn) / (1000 * 60 * 60) : Infinity;
    setCheckInAvailable(hoursSinceLastCheckIn >= 24);
  }, [gameState.lastCheckIn]);

  const debouncedSave = useCallback(
    debounce((state) => {
      console.log('Debounced save triggered with state:', state);
      saveUserData(username, state);
    }, 1000),
    [username]
  );

  useEffect(() => {
    debouncedSave(gameState);
  }, [gameState, debouncedSave]);

  const handleTap = useCallback(() => {
    setGameStateAndSave((prevState) => ({
      ...prevState,
    }));
  }, []);




  const handleExpGain = useCallback((amount) => {
    setGameStateAndSave((prevState) => ({
      ...prevState,
      exp: prevState.exp + amount,
    }));
  }, []);

  useEffect(() => {
    const passiveCookieInterval = setInterval(() => {
      setGameStateAndSave((prevState) => {
        const stage = evolutionStages[prevState.relationshipStage];
        const baseProduction = stage ? stage.cookiesPerHour : 0;
        const multiplier = prevState.passiveCookieMultiplier || 1;
        const cookiesProduced = (baseProduction / 60) * multiplier;

        return {
          ...prevState,
          cookieBalance: prevState.cookieBalance + cookiesProduced,
        };
      });
    }, 60000);

    return () => clearInterval(passiveCookieInterval);
  }, [setGameStateAndSave]);

  const handleStageChange = useCallback((newStage) => {
    setGameStateAndSave((prevState) => ({
      ...prevState,
      relationshipStage: newStage,
    }));
  }, []);

  const getMinimumTapsForUpgrade = useCallback((stage) => {
    switch (stage) {
      case 'Stranger':
        return 100;
      case 'Acquaintance':
        return 500;
      case 'Friend':
        return 1000;
      case 'CloseFriend':
        return 2000;
      default:
        return 5000;
    }
  }, []);

  const getCriticalRegionThreshold = useCallback((stage) => {
    const maxHearts = (() => {
      switch (stage) {
        case 'Stranger':
          return 100;
        case 'Acquaintance':
          return 500;
        default:
          return 10000;
      }
    })();
    return Math.floor(maxHearts * 0.9);
  }, []);



  useEffect(() => {
    if (tg) {
      tg.ready();
      tg.expand();

      tg.BackButton.onClick(() => {
        closeMenus();
      });

      tg.onEvent('viewportChanged', handleViewportChanged);
      tg.onEvent('mainButtonClicked', handleMainButtonClicked);

      const initData = tg.initDataUnsafe;
      if (initData && initData.user) {
        const username = initData.user.username || initData.user.first_name || 'Anonymous';
        setUsername(username);

        saveUserData(username, {}).then((objectId) => {
          if (objectId) {
            setUserId(objectId);
            loadUserData(objectId).then((loadedGameState) => {
              if (loadedGameState) {
                setGameState(loadedGameState);
              }
            });
          }
        });
      }
    }

    return () => {
      if (tg) {
        tg.offEvent('viewportChanged', handleViewportChanged);
        tg.offEvent('mainButtonClicked', handleMainButtonClicked);
      }
    };
  }, []);

  useEffect(() => {
    if (socket) {
      const handleMessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === 'updateCookieBalance') {
            setGameStateAndSave(prevState => ({
              ...prevState,
              cookieBalance: data.newBalance
            }));
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };
  
      socket.addEventListener('message', handleMessage);
  
      return () => {
        socket.removeEventListener('message', handleMessage);
      };
    }
  }, [socket, setGameStateAndSave]);

    useEffect(() => {
    const tapLimitInterval = setInterval(() => {
      setGameState((prevState) => ({
        ...prevState,
        tapLimit: Math.min(prevState.tapLimit + 1, maxTapLimit),
      }));
    }, 1000);

    return () => clearInterval(tapLimitInterval);
  }, [maxTapLimit]);

  useEffect(() => {
    if (gameState.gameStage === 'character') {
      setShowIndicators(true);
      const timer = setTimeout(() => setShowIndicators(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [gameState.gameStage]);

  const handleViewportChanged = (event) => {
    console.log('Viewport changed:', event);
  };

  const handleMainButtonClicked = () => {
    console.log('Main button clicked');
  };

  useEffect(() => {
    if (isNightTime) {
      console.log("It's night time!");
    }
  }, [isNightTime]);

  useEffect(() => {
    const startTime = Date.now();
    const minLoadingTime = 4000;

    const preloadAssets = async () => {
      const imagesToPreload = [
        cutebase,
        happypat,
        happyfeed,
        happystate,
        eggImage,
        slightlyCrackedEgg,
        veryCrackedEgg,
        kawaiistatus,
        peachangry,
        peachhappy,
        peachnom2,
        peachcontent,
        peachsad,
        peachdefault,
        peachstatus,
        appleImage,
        kitchenBackground,
      ];

      const videosToPreload = [
        eggHatchingVideo,
        peachnom1,
        peachPattingAnimation,
        peachidle,
      ];

      const audioToPreload = [eggHatchingSound];

      const preloadImage = (src) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => resolve(src);
          img.onerror = () => {
            console.error(`Failed to load image: ${src}`);
            resolve(null);
          };
          img.src = src;
        });
      };

      const preloadVideo = (src) => {
        return new Promise((resolve) => {
          const video = document.createElement('video');
          video.preload = 'auto';
          video.oncanplaythrough = () => resolve(src);
          video.onerror = () => {
            console.error(`Failed to load video: ${src}`);
            resolve(null);
          };
          video.src = src;
          video.load();
        });
      };

      const preloadAudio = (src) => {
        return new Promise((resolve) => {
          const audio = new Audio();
          audio.oncanplaythrough = () => resolve(src);
          audio.onerror = () => {
            console.error(`Failed to load audio: ${src}`);
            resolve(null);
          };
          audio.src = src;
          audio.load();
        });
      };

      const totalAssets = imagesToPreload.length + videosToPreload.length + audioToPreload.length;
      let loadedAssets = 0;

      const updateProgress = () => {
        loadedAssets++;
        const assetProgress = (loadedAssets / totalAssets) * 100;
        const elapsedTime = Date.now() - startTime;
        const timeProgress = Math.min((elapsedTime / minLoadingTime) * 100, 100);

        setLoadingProgress(Math.max(assetProgress, timeProgress));
      };

      try {
        const results = await Promise.all([
          ...imagesToPreload.map((src) => preloadImage(src).then(updateProgress)),
          ...videosToPreload.map((src) => preloadVideo(src).then(updateProgress)),
          ...audioToPreload.map((src) => preloadAudio(src).then(updateProgress)),
        ]);

        const failedAssets = results.filter((result) => result === null);
        if (failedAssets.length > 0) {
          console.warn(`${failedAssets.length} assets failed to load. The app will continue, but some content may be missing.`);
        }

        console.log('All assets preloaded successfully');
      } catch (error) {
        console.error('Error during asset preloading:', error);
      } finally {
        setLoadingProgress(100);
      }
    };

    preloadAssets();

    const minLoadingTimer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      if (elapsedTime >= minLoadingTime) {
        clearInterval(minLoadingTimer);
        setIsLoading(false);
        setIsLoadingComplete(true);
      } else {
        const progress = (elapsedTime / minLoadingTime) * 100;
        setLoadingProgress(Math.max(loadingProgress, progress));
      }
    }, 50);

    const loadingTimeout = setTimeout(() => {
      console.warn('Loading timeout reached. Forcing app to start.');
      setLoadingProgress(100);
      setIsLoading(false);
      setIsLoadingComplete(true);
    }, 20000);

    return () => {
      clearInterval(minLoadingTimer);
      clearTimeout(loadingTimeout);
    };
  }, []);

  useEffect(() => {
    setCharacterStageReady(true);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveUserData(username, gameState);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [username, gameState]);

  const fetchWorkplace = async () => {
    try {
      const response = await fetch(`/api/user/${username}`);
      const data = await response.json();
      setWorkplace(data.workplace);
    } catch (error) {
      console.error('Error fetching workplace:', error);
    }
  };

  const handleWorkplaceSelection = async (selectedWorkplace) => {
    try {
      const response = await fetch('/api/workplace/set', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: username, workplace: selectedWorkplace }),
      });
      const data = await response.json();
      setWorkplace(data.workplace);
    } catch (error) {
      console.error('Error setting workplace:', error);
    }
  };



  const updateCookieProduction = useCallback(async () => {
    try {
      const response = await fetch(`/api/cookies/produce?userId=${username}`);
      const data = await response.json();
      setGameStateAndSave((prevState) => ({
        ...prevState,
        cookieBalance: prevState.cookieBalance + data.cookiesProduced,
        bakery: {
          ...prevState.bakery,
          efficiency: data.efficiency,
        },
      }));
    } catch (error) {
      console.error('Error updating cookie production:', error);
    }
  }, [username, setGameStateAndSave]);

  useEffect(() => {
    const productionInterval = setInterval(updateCookieProduction, 3600000);
    return () => clearInterval(productionInterval);
  }, [updateCookieProduction]);

  const handleCookieBaked = useCallback(() => {
    setGameStateAndSave((prevState) => ({
      ...prevState,
      cookieBalance: prevState.cookieBalance + 1,
    }));
  }, []);

  const setTapLimit = useCallback(
    (newLimit) => {
      setGameStateAndSave((prevState) => ({
        ...prevState,
        tapLimit: Math.max(0, Math.min(newLimit, maxTapLimit)),
      }));
    },
    [maxTapLimit]
  );

  const setOverallMood = useCallback(
    (newMood) => {
      setGameStateAndSave((prevState) => ({
        ...prevState,
        overallMood: newMood,
      }));
    },
    [setGameState]
  );

  useEffect(() => {
    console.log('Overall mood changed, forcing re-render:', gameState.overallMood);
    setGameState((prevState) => ({ ...prevState }));
  }, [gameState.overallMood]);

  const triggerHapticFeedback = (type) => {
    if (typeof WebApp !== 'undefined' && WebApp.HapticFeedback) {
      switch (type) {
        case 'tap':
          WebApp.HapticFeedback.impactOccurred('medium');
          break;
        case 'feed':
          WebApp.HapticFeedback.impactOccurred('medium');
          break;
        case 'upgrade':
          WebApp.HapticFeedback.notificationOccurred('success');
          setTimeout(() => WebApp.HapticFeedback.notificationOccurred('success'), 200);
          break;
        case 'downgrade':
          WebApp.HapticFeedback.notificationOccurred('warning');
          setTimeout(() => WebApp.HapticFeedback.notificationOccurred('warning'), 200);
          break;
      }
    }
  };



  const maxExp = 1000;

  if (isLoading || !isLoadingComplete) {
    return <LoadingScreen progress={loadingProgress} />;
}
//#region AppReturn
return (
  <ErrorBoundary
    fallback={({ error }) => (
      <div className="flex flex-col items-center justify-center h-screen bg-red-100 p-4">
        <h1 className="text-2xl font-bold text-red-700 mb-4">Something went wrong</h1>
        <p className="text-red-600 mb-4">{error.message}</p>
        <button
          onClick={() => window.location.reload()}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Reload App
        </button>
      </div>
    )}
  >
    <div
      className={`flex flex-col min-h-screen w-full app-container ${gameState.mood.toLowerCase()} ${
        isDarkMode ? 'dark-mode' : 'light-mode'
      } relative overflow-hidden`}
    >
      <div id="mainGameScreen" className="flex-grow flex flex-col relative">
        <div className="main-header-container w-full">
          <CustomHeader
            exp={gameState.exp}
            maxExp={maxExp}
            cookiesPerHour={cookiesPerHour}
            isDarkMode={isDarkMode}
            username={username}
            showBack={showBack}
            hideBack={hideBack}
            onOpenHireFriends={openHireFriendsScreen}
          />
        </div>

        {isNightTime && <div className="fixed inset-0 bg-black bg-opacity-30 pointer-events-none z-10"></div>}

        <main className="flex-grow flex flex-col justify-center items-center p-4 relative overflow-hidden">
          <CharacterStage
            stage={gameState.relationshipStage}
            onExpGain={handleExpGain}
            onStageChange={handleStageChange}
            onCookieBaked={handleCookieBaked}
            characterType={gameState.selectedCharacter}
            exp={gameState.exp}
            tapLimit={gameState.tapLimit}
            setTapLimit={setTapLimit}
            moodThresholds={moodThresholds}
            moodInteractions={moodInteractions}
            handleOpenStatus={handleOpenStatus}
            handleOpenInvestment={handleOpenInvestment}
            handleCloseStatus={handleCloseStatus}
            handleCloseInvestment={handleCloseInvestment}
            setOverallMood={setOverallMood}
            isDarkMode={isDarkMode}
            username={username}
            setGameState={setGameStateAndSave}
            gameState={gameState}
            hearts={gameState.hearts}
            comfort={gameState.comfort}
            fullness={gameState.fullness}
            mood={gameState.mood}
            triggerHapticFeedback={triggerHapticFeedback}
            speechText={speechText}
            setSpeechText={setSpeechText}
            maxTapLimit={maxTapLimit}
            speechMessages={speechMessages}
            setSpeechMessages={setSpeechMessages}
            socket={socket}
          />
        </main>



      </div>

      {showHireFriends && (
        <HireFriendsScreen
          onClose={closeHireFriendsScreen}
          isDarkMode={isDarkMode}
          username={username}
          referralCode={referralCode}
          isReferralCodeLoading={isReferralCodeLoading}
          BOT_USERNAME={BOT_USERNAME}
          cookieBalance={gameState.cookieBalance}
          gameState={gameState}
          setGameState={setGameStateAndSave}
          onTwitterBonusClaimed={(newBalance) => setGameStateAndSave(prevState => ({
            ...prevState,
            cookieBalance: newBalance,
            twitterFollowerBonusClaimed: true
          }))}
          WebApp={window.Telegram?.WebApp}
        />
      )}

      {(showStatus || leftMenuOpen) && (
        <StatusScreen
          stage={gameState.relationshipStage}
          gameState={gameState}
          exp={gameState.exp}
          taps={gameState.taps}
          cookieBalance={gameState.cookieBalance}
          characterType={gameState.selectedCharacter}
          isDarkMode={isDarkMode}
          getMinimumTapsForUpgrade={getMinimumTapsForUpgrade}
          getCriticalRegionThreshold={getCriticalRegionThreshold}
          onClose={handleCloseStatus}
          username={username}
        />
      )}

      {(showInvestment || rightMenuOpen) && (
        <div className="fixed inset-0 z-50 overflow-hidden">
          <InvestmentScreen
            cookieCount={gameState.cookieBalance}
            checkInAvailable={checkInAvailable}
            consecutiveCheckIns={gameState.consecutiveCheckIns}
            username={username}
            isDarkMode={isDarkMode}
            onClose={handleCloseInvestment}
            showBack={showBack}
            hideBack={hideBack}
            gameState={gameState}
            setGameState={setGameStateAndSave}
            bakery={gameState.bakery}
            socket={socket}
            lastCheckIn={lastCheckIn}
            nextCheckIn={nextCheckIn}
          />
        </div>
      )}

      {showHelp && <HelpScreen onClose={() => setShowHelp(false)} isDarkMode={isDarkMode} />}
    </div>
  </ErrorBoundary>
);
}
export default App;